import { pluck } from './object';

const { toString } = Object.prototype;
const { MAX_SAFE_INTEGER } = Number;

const dateTag = '[object Date]';
const identity = (x) => x;

export const not =
  (fn) =>
  (...xs) =>
    !fn(...xs);

// NOTE: This is true for null or undefined values but false for all others, since (non-strictly or 'double')
// equals between null and undefined values is true but false when compared to any other values, including
// other falsey values (CJP)
export const isNil = (x) => x == undefined;
export const exists = (x) => !isNil(x);
export const isBoolean = (x) => x === true || x === false;
export const isObjectLike = (x) => typeof x === 'object' && x !== null;
export const isDate = (x) => isObjectLike(x) && toString.call(x) === dateTag;
export const isString = (x) => typeof x === 'string';
export const isFunction = (x) => typeof x === 'function';
export const isNumber = (x) => typeof x === 'number';
export const isArray = Array.isArray.bind(Array);

export const isEqualTo =
  (y, pluckFn = identity) =>
  (x) =>
    pluckFn(y) == pluckFn(x);
export const isStrictlyEqualTo = (y) => (x) => y === x;
export const isOneOf =
  (...ys) =>
  (x) =>
    ys.some(isEqualTo(x));
export const hasShapeOf = (o) => (x) =>
  isObjectLike(x) && Object.keys(o).every((k) => o[k](x[k]));
export const hasProperty = (p) => {
  const getter = pluck(p);
  return (...os) => os.some((o) => isObjectLike(o) && getter(o));
};
export const doesNotHaveProperty = (p) => {
  const getter = pluck(p);
  return (...os) => os.some((o) => isObjectLike(o) && !getter(o));
};

export const or =
  (...preds) =>
  (...args) =>
    preds.some((pred) => pred(...args));

export const and =
  (...preds) =>
  (...args) =>
    preds.every((pred) => pred(...args));

export const isEmpty = (o) => {
  return (
    (Array.isArray(o) && o.length === 0) ||
    (isObjectLike(o) && Object.keys(o).length === 0)
  );
};

export const isEmptyObject = (o) => {
  return isObjectLike(o) && !Array.isArray(o) && Object.keys(o).length === 0;
};
