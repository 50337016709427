import { useEffect, useRef } from 'react';

// Looking for name suggestions...
// Used for cases where we were watching a "value$" stream, and dispatching
// something when that stream emitted a value. This should instead store the last
// value we receive from props for comparison against future values. If there is a change
// we'll update the ref and call the provided onChange (useful for dispatching or passing a change)
// up and out.
const useOnOutsideValueChange = (valueProp, onChange) => {
  const lastValueRef = useRef();

  useEffect(() => {
    if (valueProp !== lastValueRef.current) {
      lastValueRef.current = valueProp;
      onChange(valueProp);
    }
  }, [valueProp, onChange]);

  return lastValueRef;
};

export default useOnOutsideValueChange;
