import { useState } from 'react';

/**
 * @callback SetAutoFocus
 * @param {HTMLElement=} elementRef
 * @returns {void}
 */

export default (autofocus) => {
  const [hasFocused, setHasFocused] = useState(false);

  /**
   * @type {SetAutoFocus}
   */
  const setAutoFocus = (elementRef) => {
    if (!autofocus || !elementRef || hasFocused) return;
    /**
     * @type {HTMLElement}
     */
    const typedElementRef = elementRef;
    typedElementRef.focus();
    return setHasFocused(true);
  };
  return setAutoFocus;
};
