import { isNil } from '../fp/pred';
import { isoStrAsLuxon } from './ISODates';
import { luxonToDisplayString } from '../format/luxonToDisplayString';

/**
 * @param {string} [isoString] - a date or datetime string in one of the ISO formats we support
 * @param {string} format - one of the strings from the localeFormat enum
 * @return {string|undefined} a string in a locale date format for display purposes
 */
export const toDisplayDateFromISOString = (isoString, format) => {
  if (isNil(isoString)) return isoString;
  const dateTime = isoStrAsLuxon(isoString);
  return luxonToDisplayString(dateTime, format);
};
