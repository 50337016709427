import { isFunction } from '../fp/pred';

const lazySymbol = Symbol.for('react.lazy');
export const exportComponentReducer = (components, [moduleName, exports]) => {
  if (isFunction(exports)) {
    return {
      ...components,
      [moduleName]: {
        fn: exports,
        name: exports.name,
      },
    };
  }

  const {
    $$typeof
  } = exports;

  if ($$typeof === lazySymbol) {
    return {
      ...components,
      [moduleName]: {
        component: exports,
        name: exports.name,
      },
    };
  }
  
  const exportedComponents = Object.entries(exports)
    .filter(([, fn]) => isFunction(fn))
    .reduce((acc, [name, fn]) => {
      const exportedName = name === 'default' ? moduleName : name;
      return {
        ...acc,
        [exportedName]: {
          name,
          fn,
        },
      };
    }, {});

  return {
    ...components,
    ...exportedComponents,
  };
};
