import { downloadFile } from '../download/downloadFile';
import { ofType } from '../frp/operators/ofType';
import { combineWithLatestFrom } from '../frp/operators/combineWithLatestFrom';
import { ofChangedPropWhenExists } from '../frp/operators/ofChangedPropWhenExists';
import { forkJoin, of } from 'rxjs';
import {
  map,
  mergeMap,
  pluck,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import { addFile, getFile } from 'services/file-storages/xe-file-storages-svc';
import {
  addDocument,
  getDocuments,
  updateDocument,
} from 'services/scan-docs/xe-scan-docs-svc';
import { printReport } from 'services/xe-reportings/xe-xe-reportings-svc';
import { neverCache } from '../service/serviceCache';
import { toMenuNodeRequestFn } from '../operators/toMenuNodeRequestFn';
import {
  DID_GET_SCAN_DOC_ID,
  DID_PROMPT_FILE_DOWNLOAD,
  DID_SAVE_FILE_SET,
  DID_UPLOAD_FILES,
  SHOULD_ADD_DOCUMENT,
  SHOULD_DOWNLOAD_DOCUMENT,
  SHOULD_DOWNLOAD_FILE,
  SHOULD_DOWNLOAD_REPORT,
  SHOULD_UPDATE_DOCUMENT,
  SHOULD_UPDATE_SCAN_DOCS_TO,
  SHOULD_UPLOAD_FILES,
} from './actions';
import { FILE_WAS_TOO_LARGE_MESSAGE, OUTPUT_TYPE_PDF } from './constants';
import {
  HEADER_CONTENT_TYPE,
  HEADER_XE_ENTERPRISE_ID,
} from '../service/constants';

const toEnterpriseHeader = (enterpriseId) => {
  if (!enterpriseId) return;
  return {
    [HEADER_XE_ENTERPRISE_ID]: enterpriseId,
  };
};

export const toUploadFile$Epic = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_UPLOAD_FILES),
    pluck('value'),
    withLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists('fileSetEnterpriseId'),
        startWith('')
      ),
      state$.pipe(toMenuNodeRequestFn())
    ),
    mergeMap(([files, enterpriseId, baseRequestFn]) => {
      const headers = Object.assign(
        {},
        enterpriseId && { [HEADER_XE_ENTERPRISE_ID]: enterpriseId },
        { [HEADER_CONTENT_TYPE]: undefined }
      );
      const toAddFileRequest = (file) => {
        const request = addFile(
          file,
          {},
          baseRequestFn({
            headers,
            spy: {
              errorSpy: (err) => {
                if (err.status === 413) {
                  throw new Error(FILE_WAS_TOO_LARGE_MESSAGE);
                } else {
                  throw err;
                }
              },
            },
          })
        );

        return request.pipe(
          map((response) => ({
            response,
            file,
          }))
        );
      };

      if (files && !Array.isArray(files)) {
        console.warn(
          'An array was expected to be provided to the file upload handler.'
        );
        return toAddFileRequest(files).pipe(
          map((uploadedFile) => ({
            type: DID_UPLOAD_FILES,
            value: [uploadedFile],
          }))
        );
      }

      const arrayOfRequests = files.map(toAddFileRequest);

      return forkJoin(arrayOfRequests).pipe(
        map((uploadedFiles) => ({
          type: DID_UPLOAD_FILES,
          value: uploadedFiles,
        }))
      );
    })
  );
};

export const toAddDocument$Epic = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_ADD_DOCUMENT),
    withLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists('fileSetEnterpriseId'),
        startWith('')
      ),
      state$.pipe(toMenuNodeRequestFn())
    ),
    mergeMap(([value, enterpriseId, baseRequestFn]) => {
      const { value: fileSet, changed } = value;
      return addDocument(
        fileSet,
        {},
        baseRequestFn({ headers: toEnterpriseHeader(enterpriseId) })
      ).pipe(
        map((response) => ({
          type: DID_SAVE_FILE_SET,
          value: { ...response, ...fileSet },
          changed,
        }))
      );
    })
  );
};

export const toUpdateDocument$Epic = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_UPDATE_DOCUMENT),
    withLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists('fileSetEnterpriseId'),
        startWith('')
      ),
      state$.pipe(toMenuNodeRequestFn())
    ),
    mergeMap(([value, enterpriseId, baseRequestFn]) => {
      const { value: fileSet, changed } = value;
      return updateDocument(
        fileSet,
        { scanDocId: fileSet.ScanDocID },
        baseRequestFn({ headers: toEnterpriseHeader(enterpriseId) })
      ).pipe(
        map((response) => ({
          type: DID_SAVE_FILE_SET,
          value: { ...response, ...fileSet },
          changed,
        }))
      );
    })
  );
};

export const toExistingDocuments$Epic = (action$, state$) => {
  return action$.pipe(
    ofType(DID_GET_SCAN_DOC_ID),
    pluck('value'),
    combineWithLatestFrom(
      state$.pipe(
        ofChangedPropWhenExists('fileSetEnterpriseId'),
        startWith('')
      ),
      state$.pipe(toMenuNodeRequestFn(neverCache))
    ),
    mergeMap(([scanDocId, enterpriseId, toRequest$]) => {
      if (!scanDocId) {
        return of({
          type: SHOULD_UPDATE_SCAN_DOCS_TO,
          value: {},
        });
      }
      return getDocuments(
        { scanDocId },
        toRequest$({ headers: toEnterpriseHeader(enterpriseId) })
      ).pipe(
        map(([response]) => ({
          type: SHOULD_UPDATE_SCAN_DOCS_TO,
          value: response,
        }))
      );
    })
  );
};

export const toFileData$Epic = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_DOWNLOAD_DOCUMENT),
    pluck('value'),
    map((document) => {
      // Should we start try/catching when destructuring data from the server? (JDM)
      const {
        IPID: { IPID },
        ReportID,
        VisitAssessmentID,
      } = document;
      return {
        OutputType: OUTPUT_TYPE_PDF,
        Report: [
          {
            Parameter: [
              {
                Name: 'IPID',
                Value: `${IPID}`,
              },
              {
                Name: 'VisitAssessmentID',
                Value: `${VisitAssessmentID}`,
              },
            ],
            ReportID,
          },
        ],
        ReturnResults: true,
      };
    }),
    combineWithLatestFrom(state$.pipe(toMenuNodeRequestFn())),
    mergeMap(([requestBody, toRequest$]) => {
      return printReport(requestBody, {}, toRequest$());
    }),
    map(([response]) => {
      const {
        Report: [FirstReport],
      } = response;
      return {
        type: SHOULD_DOWNLOAD_REPORT,
        value: FirstReport,
      };
    })
  );
};

export const toDownloadReportEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_DOWNLOAD_REPORT),
    pluck('value'),
    combineWithLatestFrom(state$.pipe(toMenuNodeRequestFn())),
    mergeMap(([report = {}, toRequest$]) => {
      return getFile({ fileId: report.FileID }, toRequest$()).pipe(
        map((response) => {
          downloadFile(response, report.Name);
          return {
            type: DID_PROMPT_FILE_DOWNLOAD,
          };
        })
      );
    })
  );
};

export const toDownloadFileEpic$ = (action$, state$) => {
  return action$.pipe(
    ofType(SHOULD_DOWNLOAD_FILE),
    pluck('value'),
    combineWithLatestFrom(state$.pipe(toMenuNodeRequestFn())),
    mergeMap(([{ fileId, fileName }, toRequest$]) => {
      return getFile({ fileId }, toRequest$()).pipe(
        map((response) => {
          downloadFile(response, fileName);
          return {
            type: DID_PROMPT_FILE_DOWNLOAD,
            value: response,
          };
        })
      );
    })
  );
};

export default [
  toUploadFile$Epic,
  toAddDocument$Epic,
  toUpdateDocument$Epic,
  toExistingDocuments$Epic,
  toDownloadReportEpic$,
  toDownloadFileEpic$,
  toFileData$Epic,
];
