import { NOOP_FUNCTION } from '../constants';
import { isFunction } from './pred';

export const identity = (o) => o;

/**
 * Defaults an argument to a no-op if it is not a function
 * @param {*} f Value to cast
 * @returns {Function} A casted function
 */
export const castFunction = (f) => (isFunction(f) ? f : NOOP_FUNCTION);

export const pipe =
  (...reducers) =>
  (data) => {
    return reducers.reduce((acc, reducer) => {
      return reducer(acc);
    }, data);
  };

export const combineReducers = (...reducers) => {
  return (state, action) =>
    reducers.reduce((acc, reducer) => reducer(acc, action), state);
};
