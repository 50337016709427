import { Outlet, useParams } from 'react-router-dom';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';
import { useMenuNode } from '../../contexts/XeMenuNodeContext';

import { useCallback } from 'react';
import { TabStrip, TabStripTab } from '../../components/TabStrip';
import { Flexbox } from '../../components/Flexbox';
import { usePathNavigate } from '../../hooks/usePathNavigate';
import { matchesMenuNode } from '../../contexts/XeMenuNodeContext/components';

const DefaultLayout = () => (
  <Flexbox direction="column" className="flex-1">
    <Outlet />
  </Flexbox>
);

const MenuGroupLayout = (props) => {
  const menuNode = useMenuNode();
  const navigate = usePathNavigate();
  const { ['*']: RouteName } = useParams();

  const {
    XeAppMenuNode: {
      MenuGroup: { Layout = 'MENU_BAR' } = EMPTY_OBJECT,
      XeAppMenus = EMPTY_ARRAY,
    } = EMPTY_OBJECT,
  } = menuNode;

  const selectedChildIndex = XeAppMenus.findIndex((item) =>
    matchesMenuNode(item, RouteName)
  );

  const toNavigateToFeature = useCallback(
    ({ selected }) => {
      const XeAppMenuNode = XeAppMenus[selected] ?? EMPTY_OBJECT;

      navigate(XeAppMenuNode);
    },
    [navigate, XeAppMenus]
  );

  switch (Layout) {
    case 'MENU_BAR': {
      return (
        <TabStrip
          selected={selectedChildIndex}
          onSelect={toNavigateToFeature}
          keepTabsMounted={false}
          // onSelect={index => should}
        >
          {XeAppMenus.map((XeAppMenuNode) => {
            const { Name, MenuNodeID } = XeAppMenuNode;
            return (
              <TabStripTab
                dataElementName={Name}
                key={`${Name}_${MenuNodeID}`}
                title={Name}
              >
                <DefaultLayout />
              </TabStripTab>
            );
          })}
        </TabStrip>
      );
    }
    default:
      return DefaultLayout;
  }
};

/** This is used when there is a menu node in the hierarchy that exists only for the purpose of facilitating subnavigation
 * For example, we want to display some form of menu bar that allows us to get to the children beneath. Eventually the side
 * menu needs to fall within this realm instead of the outdated XePassThroughRoute
 **/
export const XeMenuRoute = (props) => {
  return <MenuGroupLayout {...props} />;
};
