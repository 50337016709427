import { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { useFactory } from './useFactory';

export const useRef$ = (value) => {
  const ref$ = useFactory(() => new BehaviorSubject(value), []);

  useEffect(() => {
    if (value !== ref$.value) {
      ref$.next(value);
    }
  }, [ref$, value]);

  return ref$;
};
