import { forkJoin, of } from 'rxjs';
import { map, mergeMap, pluck, withLatestFrom, tap } from 'rxjs/operators';
import { addFile } from 'services/file-storages/xe-file-storages-svc';
import { browse } from 'services/scan-doc-sets/xe-scan-doc-sets-svc';
import { printReport } from 'services/xe-reportings/xe-xe-reportings-svc';
import { getFile } from 'services/file-storages/xe-file-storages-svc';
import {
  addDocument,
  updateDocument,
  getDocuments,
} from 'services/scan-docs/xe-scan-docs-svc';
import { isNil } from 'lodash';
import { downloadFile as download } from '../download/downloadFile';
import { neverCache } from '../service/serviceCache';
import {
  HEADER_CONTENT_TYPE,
  HEADER_XE_ENTERPRISE_ID,
} from '../service/constants';

/**
 * @typedef BaseOptions
 * @property {string} enterpriseId
 */

/**
 * @typedef WithNewScanDocOptions
 * @property {import('rxjs').Observable=} enterpriseId$ Observable of values to use for the 'xe-enterprise-id' header
 * @property {string} enterpriseId Value to use for the 'xe-enterprise-id' header. Supersedes `enterpriseId$`
 */

/**
 * Function that returns an operator that provides `[sourceValue, xeScanDoc, toRequeust$]`
 */
const toWithRequestDataOperator =
  (xeScanDoc, menuNode$, options) => (source$) => {
    const { enterpriseId, enterpriseId$ } = options;
    const _enterpriseId$ = (() => {
      if (enterpriseId) {
        return of(enterpriseId);
      }
      return enterpriseId$ || of('');
    })();

    return source$.pipe(
      withLatestFrom(
        _enterpriseId$,
        menuNode$.pipe(
          pluck('requestFn'),
          map((fn) => fn())
        )
      ),
      map(([sourceValue, enterpriseId, toRequest$]) => {
        const _xeScanDoc =
          typeof xeScanDoc === 'function' ? xeScanDoc(sourceValue) : xeScanDoc;

        const maybeXeEnterpriseIdHeader = (() => {
          if (!enterpriseId) return;
          return { [HEADER_XE_ENTERPRISE_ID]: enterpriseId };
        })();
        return [
          sourceValue,
          _xeScanDoc,
          (o) =>
            toRequest$({
              fullRequest: true,
              headers: maybeXeEnterpriseIdHeader,
              ...o,
            }),
        ];
      })
    );
  };

/**
 * @callback ToXeScanDocOperatorFn
 * @param {{ [x: string]: any } | ((sourceValue: T) => { [x: string]: any })} xeScanDoc
 * @param {import('rxjs').Observable} menuNode$
 * @param {BaseOptions & WithNewScanDocOptions} options
 * @return {import('rxjs').OperatorFunction}
 * @template T
 */

/**
 * Returns an operator that updates an existing XeScanDoc. Makes a request to add a scan doc
 * if no ScanDocID is provided.
 * @type {ToXeScanDocOperatorFn<T>}
 * @template T
 */
export const withUpdatedScanDoc =
  (xeScanDoc, menuNode$, options = {}) =>
  (source$) => {
    return source$.pipe(
      toWithRequestDataOperator(xeScanDoc, menuNode$, options),
      mergeMap(([sourceValue, xeScanDoc, toRequest$]) => {
        const { ScanDocID } = xeScanDoc;
        const requestFn = isNil(ScanDocID) ? addDocument : updateDocument;
        return requestFn(
          xeScanDoc,
          { scanDocId: xeScanDoc.ScanDocID },
          toRequest$({ fullRequest: true })
        ).pipe(
          pluck('results'),
          map((response) => [sourceValue, response])
        );
      })
    );
  };

/**
 * Operator that uploads a set of files returned from an <input type="file" /> element
 * @param {File[] | ((sourceValue: T) => File[])} files
 * @param {import('rxjs').Observable} menuNode$
 * @param {BaseOptions} options
 * @return {import('rxjs').OperatorFunction}
 * @template T
 */
export const withUploadedFiles =
  (files, menuNode$, options = {}) =>
  (source$) => {
    return source$.pipe(
      withLatestFrom(
        menuNode$.pipe(
          pluck('requestFn'),
          /*
           * NOTE: SEE SYNUI-6961
           *
           * The addFile requests seem to periodically get a cached response back instead of uploading a new file (JDM)
           */
          map((fn) => fn(neverCache)())
        )
      ),
      mergeMap(([sourceValue, toRequest$]) => {
        const { enterpriseId } = options;

        const filesToUpload =
          typeof files === 'function' ? files(sourceValue) : files;
        const headers = Object.assign(
          {},
          enterpriseId && { [HEADER_XE_ENTERPRISE_ID]: enterpriseId },
          { [HEADER_CONTENT_TYPE]: undefined }
        );
        const toAddFileRequest = (file) => {
          const makeRequest = toRequest$({
            headers,
          });
          return addFile(file, {}, makeRequest);
        };

        const arrayOfRequests = filesToUpload.map(toAddFileRequest);

        return forkJoin(arrayOfRequests).pipe(
          map((uploadedFiles) => {
            return [sourceValue, uploadedFiles];
          })
        );
      })
    );
  };

/**
 * @typedef BrowseOptions
 * @property {string} docTypeId
 * @property {string} categoryId
 * @property {string} documentTypeId
 * @property {number} ipid
 * @property {string} statusCode
 * @property {string} uploadDateMin
 * @property {string} uploadDateMax
 */

/**
 * Requests scan doc sets
 * @param {(value: T, index: number) => BrowseOptions} project
 * @param {import('rxjs').Observable} menuNode$
 * @template T
 */
export const browseScanDocSets = (project, menuNode$) => (source$) => {
  return source$.pipe(
    map(project),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([browseOptions, toRequest$]) => {
      return browse(browseOptions, toRequest$({ fullRequest: true }));
    })
  );
};

/**
 * Gets scan doc data from a provided scan doc id
 * @param {(value: T, index: number) => number} project
 * @param {import('rxjs').Observable} menuNode$
 * @template T
 */
export const getScanDoc = (project, menuNode$) => (source$) => {
  return source$.pipe(
    map(project),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn(neverCache)())
      )
    ),
    mergeMap(([scanDocId, toRequest$]) => {
      return getDocuments({ scanDocId }, toRequest$({ fullRequest: true }));
    })
  );
};

/**
 * Gets report data from a provided object
 * @param {(value: T, index: number) => number} project
 * @param {import('rxjs').Observable} menuNode$
 * @template T
 */
export const getReportData = (project, menuNode$) => (source$) => {
  return source$.pipe(
    map(project),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([requestBody, toRequest$]) => {
      return printReport(requestBody, {}, toRequest$({ fullRequest: true }));
    })
  );
};

/**
 * Gets file Data from a provided object
 * @param {(value: T) => { fileId: number, [x: string]: any }} project
 * @param {import('rxjs').Observable} menuNode$
 * @returns {import('rxjs').OperatorFunction<any, { response: any, fileData: { fileId: number, [x: string]: any } }>}
 * @template T
 */
export const getFileData = (project, menuNode$) => (source$) => {
  return source$.pipe(
    map(project),
    withLatestFrom(
      menuNode$.pipe(
        pluck('requestFn'),
        map((fn) => fn())
      )
    ),
    mergeMap(([fileData = {}, toRequest$]) => {
      const { fileId, xxsid } = fileData;
      return getFile({ fileId, xxsid }, toRequest$({ fullRequest: true })).pipe(
        map((response) => ({ response, fileData }))
      );
    })
  );
};

/**
 * Returns an operator that downloads a file from a provided object with keys blob and fileName.
 * @param {(x?: T) => { blob: Blob, fileName: string }} project
 * @template T
 */
export const downloadFile = (project) => (source$) => {
  return source$.pipe(
    map(project),
    tap(({ blob, fileName }) => {
      return download(blob, fileName);
    })
  );
};
