import { useEffect } from 'react';
import { isObservable } from 'rxjs';

//Intended to allow the creation of observable side effects
// e.g.
// useEffect$(()=>{
//   return action$.pipe(
//           ofType(SHOULD_SELECT_ITEM),
//           pluck('value'),
//           tap(v=>onChange(x))
//   );
// }, [action$, onChange]);

export const useEffect$ = (toObs$, deps) => {
  useEffect(() => {
    const obs$ = toObs$();

    if (!isObservable(obs$)) {
      //This is here as somone is going to accidently use this instead of useEffect() from React
      //they are intentionally named similar but trying to help
      throw new Error(
        'Attempt to use useEffect$ on a non-observable composition'
      );
    }

    const subscription = obs$.subscribe();

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
