export const fileSizeFormatter = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return '0 Bytes';
  }

  const sizeIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round((bytes / Math.pow(1024, sizeIndex)) * 100) / 100} ${
    sizes[sizeIndex]
  }`;
};

export default fileSizeFormatter;
