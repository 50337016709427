import { createStore, applyMiddleware } from 'redux';
import { combineEpics } from 'redux-observable';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';
import epics from './epics';
import appReducers from './reducers';

const toFlatCombineReducers = (...reducers) => {
  return (state0, action) => {
    return reducers.reduce((stateN, reducer) => {
      return reducer(stateN, action);
    }, state0);
  };
};

const toPropertyCombinedReducers = (reducers) => {
  return (state0, action) => {
    //Make this a series of function and not a loop later
    return Object.entries(reducers).reduce((stateN, [name, reducer]) => {
      return {
        ...stateN,
        [name]: reducer(stateN[name], action),
      };
    }, state0);
  };
};

const toDefaultInitialState = (dependencies) => ({ dependencies });

export default function configureStore(dependencies) {
  const proxidedDependencies = dependencies; //new Proxy(dependencies, toHandler(''));

  const epicMiddleware = createEpicMiddleware({
    dependencies: proxidedDependencies,
  });

  const { dynamicReducer, ...reducers } = appReducers;

  // const composeEnhancers =
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    //We don't want to nest the dynamic reducers, so pulling that out
    toFlatCombineReducers(
      dynamicReducer,
      toPropertyCombinedReducers({ ...reducers })
    ),
    toDefaultInitialState(proxidedDependencies),
    composeWithDevToolsLogOnlyInProduction(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(combineEpics(...epics));

  return store;
}
