import './css/theme/kendo.css';
import './css/theme/kendo-overrides.css';

import * as XeDevelopmentAuthenticator from './enterpriseContainer/XeDevelopmentAuthenticator';
import * as XeProductionAuthenticator from './enterpriseContainer/XeProductionAuthenticator';
import * as XeMissingFeature from './enterpriseContainer/XeMissingFeature';
import * as XePassThroughRoute from './enterpriseContainer/XePassThroughRoute';
import * as XeMenuRoute from './enterpriseContainer/XeMenuRoute';
import * as XePatientContext from './contexts/XePatientContext';
import * as XeMenuContext from './contexts/XeMenuContext';
import * as XeLabelContext from './contexts/XeLabelContext';
import * as XeRefDataContext from './contexts/XeRefDataContext';

export { XeAppContainer } from './enterpriseContainer/XeAppContainer';

export { XeDevelopmentAuthenticator } from './enterpriseContainer/XeDevelopmentAuthenticator';
export { XeProductionAuthenticator } from './enterpriseContainer/XeProductionAuthenticator';

export { XeMissingFeature } from './enterpriseContainer/XeMissingFeature';
export { XePassThroughRoute } from './enterpriseContainer/XePassThroughRoute';
export { XeMenuRoute } from './enterpriseContainer/XeMenuRoute';
export { XePatientContext } from './contexts/XePatientContext';
export { XeMenuContext } from './contexts/XeMenuContext';
export { XeLabelContext } from './contexts/XeLabelContext';
export { XeRefDataContext } from './contexts/XeRefDataContext';

const featureModules = {
  XeDevelopmentAuthenticator,
  XeProductionAuthenticator,
  XeMissingFeature,
  XePassThroughRoute,
  XeMenuRoute,
  XePatientContext,
  XeMenuContext,
  XeLabelContext,
  XeRefDataContext,
};

export { featureModules };

import './css/app.css';
import './css/theme/colors.css';
import './css/theme/borders.css';
import './css/theme/box-shadow.css';
import './css/theme/spacing.css';
import './css/theme/typography.css';
import './css/theme/icons.css';
import './css/theme/flex.css';
import './css/theme/displays.css';
