import { pluck, distinctUntilChanged } from 'rxjs/operators';
import { exists } from './exists';

export const ofValue =
  (value = '', { existsPredicate, distinctPredicate } = {}) =>
  (source$) =>
    source$.pipe(
      pluck(...value.split('.')),
      exists(existsPredicate),
      distinctUntilChanged(distinctPredicate)
    );
