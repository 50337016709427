import { useCallback, useState, useEffect } from 'react';
import {
  Subject,
  queueScheduler,
  BehaviorSubject,
  merge,
  asyncScheduler,
} from 'rxjs';
import { scan, tap, ignoreElements, observeOn } from 'rxjs/operators';
import { isShallowEqual } from '../fp/object';
import { useFactory } from './useFactory';

const QueueScheduler = queueScheduler.constructor;
export const useReducer$ = (reducer, epic, external) => {
  const initialState = useFactory(() =>
    reducer(undefined, { type: 'useReducer$_INIT_' })
  );
  const [state, setReactState] = useState(initialState);

  const scheduler = useFactory(() => {
    return new QueueScheduler(queueScheduler.schedulerActionCtor);
  });

  const action$ = useFactory(
    () => new Subject().pipe(observeOn(scheduler)),
    [scheduler]
  );

  const dispatch = useCallback((action) => action$.next(action), [action$]);

  useEffect(() => {
    const state$ = new BehaviorSubject(initialState);
    const reducedStateEffect$ = action$.pipe(
      scan((state, action) => {
        return reducer(state, action);
      }, initialState),
      tap((s1) => {
        const { value } = state$;

        if (!isShallowEqual(value, s1)) {
          state$.next(s1);
          setReactState(s1);
        }
      }),
      ignoreElements()
    );

    // We may want to eventually switch back to using asapScheduler instead of asyncScheduler.
    // However, there is currently a bug with asapScheduler in recent releases of rxjs:
    // https://github.com/ReactiveX/rxjs/issues/6747
    const epic$ = merge(
      reducedStateEffect$,
      epic(action$, state$.pipe(observeOn(scheduler)), external)
    ).pipe(observeOn(asyncScheduler));

    const subscription = epic$.subscribe((action) => dispatch(action));

    return () => {
      subscription.unsubscribe();
    };
  }, [epic, reducer, action$, scheduler, initialState, external, dispatch]);

  return [state, dispatch, action$];
};
