import { useMutation } from 'react-query';
import { EMPTY_OBJECT } from '../constants';
import { useMenuNode } from '../contexts/XeMenuNodeContext';
import { toCacheKeyFromRequest } from '../service/serviceCache';

// TODO: reducedConfig defaults content-type by request type (ex. put ,post), causing us
// to not be able to adjust content-type when dealing with different data types. As a result
// putting a third argument here that allows us to define header overrides. Find out
// if there's a better way to handle this (GCH)
/**
 * @param {import('react-query').MutationFunction} mutationFn Mutation function. Initially invoked as `mutationFn(EMPTY_OBJECT)` to derive the mutation's query key.
 * @param {Omit<import('react-query').UseMutationOptions, 'mutationFn'>} options
 * @returns {import('react-query').UseMutationResult}
 */
export const useXeMutation = (
  mutationFn,
  options = EMPTY_OBJECT,
  // TODO: avoid using this as it is a temporary fix used
  // to handle the uploading of files. Eventually this will be
  // removed (GCH)
  contentTypeOverrideObject = {}
) => {
  const menuNode = useMenuNode();
  const { requestConfigFn, requestInvoker } = menuNode;

  // stepping into this leads to toRequestConfigFn
  // where Content-Type is defaulted per request type (post, put, etc)
  const defaultReducedConfig = requestConfigFn()(mutationFn(EMPTY_OBJECT));
  const queryKey = toCacheKeyFromRequest(defaultReducedConfig);
  const { headers = {} } = defaultReducedConfig;

  // TODO: filtering all header overrides so we only use content-type when
  // value should be undefined to allow the uploading of files.
  // Eventually remove this (GCH)
  const contentTypeObject = Object.entries(contentTypeOverrideObject).reduce(
    (contentTypeObject, [key, value]) => {
      if (key === 'Content-Type' && value === undefined) {
        return { ...contentTypeObject, [key]: value };
      }

      return contentTypeObject;
    },
    {}
  );
  //todo: use xnet/ data/ usxemutation
  // Could be cool to take advantage of useQueryClient and setQueryData
  // but we need access to the key of a given query and that is wrapped up in
  // useXeQuery for now.
  const mutation = useMutation(
    (newEntity) => {
      // Is this a thing we want or can comfortably control from the client?
      // Seems like making a mutation request to the server (CREATE, UPDATE, DELETE)
      // cannot really be stopped once the server receives and begins processing the request
      const controller = new AbortController();
      const signal = controller.signal;
      const reducedConfig = requestConfigFn()(mutationFn(newEntity));

      const promise = requestInvoker({ fullRequest: true })({
        signal,
        ...reducedConfig,
        // TODO: remove this (GCH)
        headers: { ...headers, ...contentTypeObject },
        staleTime: 0,
      }).catch((errorResponse) => {
        const { name } = errorResponse;

        if (name !== 'AbortError') {
          throw errorResponse;
        }

        return undefined;
      });

      promise.cancel = () => {
        controller.abort();
      };

      return promise;
    },
    {
      staleTime: 0,
      retry: false,
      mutationKey: queryKey,
      ...options,
    }
  );

  return mutation;
};
