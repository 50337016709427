import window from '../globals/window';
import document from '../globals/document';

export const downloadFile = (blob, fname) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fname);
  } else {
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.style.display = 'none';

    link.href = url;
    link.download = link.download !== undefined && fname;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default downloadFile;
