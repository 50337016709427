import { useMemo } from 'react';

export default () => {
  return useMemo(
    () => [
      { nameKey: 'Individual', value: 'STAFF' },
      { nameKey: 'Organization', value: 'FACILITY' },
    ],
    []
  );
};
