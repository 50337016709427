import { DateTime, Settings } from 'luxon';

Settings.throwOnInvalid = true;

export const localeFormat = {
  MEDIUM: 'medium', // e.g. '09/14/1983'
  LONG: 'long', // e.g. '5/28/2020, 3:21 PM'
  LONG_WITH_SECONDS: 'long_with_seconds', // e.g. '3/14/1596, 4:20:24 PM'
  // Added per SYNUI-6390; revisit in the future because this is not part of the acceptable display formats
  FULL: 'full', // e.g. 'Friday, October 14, 1983, 1:30 PM Eastern Daylight Time'
  TIME: 'time', // e.g. '3:20 PM'
  RELATIVE: 'relative', // e.g. '2 days ago', 'in 57 seconds'
  RELATIVE_CALENDAR: 'relativeCalendar', // e.g. 'tomorrow', 'today', 'next month'
};

/**
 * @param {DateTime} luxonDateTime - a Luxon DateTime object
 * @param {string} format - one of the strings from the localeFormat enum
 * @return {string} a string in a locale date format for display purposes
 */
export const luxonToDisplayString = (luxonDateTime, format) => {
  if (!luxonDateTime) return luxonDateTime;

  // If you think you need to make a change to one of these cases, you better have a very, very good reason
  // Changing the code below requires an act of Congress and a resolution of the UN Security Council
  switch (format) {
    case localeFormat.MEDIUM:
      return luxonDateTime.toLocaleString(DateTime.DATE_SHORT);
    case localeFormat.LONG:
      return luxonDateTime.toLocaleString(DateTime.DATETIME_SHORT);
    case localeFormat.LONG_WITH_SECONDS:
      return luxonDateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    case localeFormat.FULL:
      return luxonDateTime.toLocaleString(DateTime.DATE_HUGE);
    case localeFormat.TIME:
      return luxonDateTime.toLocaleString(DateTime.TIME_SIMPLE);
    case localeFormat.RELATIVE:
      return luxonDateTime.toRelative();
    case localeFormat.RELATIVE_CALENDAR:
      return luxonDateTime.toRelativeCalendar();
  }

  throw new Error(
    `Format string '${format}' is invalid. Use a one of the localeFormat presets instead.`
  );
};
