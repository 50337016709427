const alreadyWarned = new Set();
const xeIconMapHandlers = {
  get: (target, prop) => {
    if (prop in target) return target[prop];
    if (!alreadyWarned.has(prop)) {
      alreadyWarned.add(prop);
      console.warn(`No icon mapping found for ${prop}.`);
    }
    return undefined;
  },
};

export const XeIconMap = new Proxy(
  {
    xe_mail_closed: 'email-outline',
    xe_patient_search: 'account-outline',
    xe_home: 'grid-large',
    xe_patients: 'account-multiple-outline',
    xe_patient_view: 'human',
    xe_scan_docs: 'file-document-outline',
    xe_calendar_feature: 'calendar-clock',
    xe_mail: 'email-outline',
    xe_network: 'forwardburger',
    xe_user_menu: 'shield-account-outline',
    xe_about: 'information-outline',
    xe_logout: 'power',
    xe_call: 'phone-classic',
    xe_password: 'settings',
    xe_help: 'help-circle-outline',
    xe_auth_menu: 'message-lock-outline',
    xe_ang_menu: 'message-alert-outline',
    xe_pqr_menu: 'account-alert-outline',
    xe_browse_menu: 'book-open-page-variant',
    xe_chart_menu: 'chart-bar',
    xe_capacity_book: 'account-clock',
    xe_capacity_config: 'calendar-cursor',
    xe_documents: 'bookshelf',
    xe_letters: 'mailbox-open-up-outline',
    xe_case_mgmt: 'file-cabinet',
    xe_claims: 'script-text-outline',
    xe_appointments: 'account-clock-outline',
    xe_file_jobs: 'file-sync-outline',
  },
  xeIconMapHandlers
);

export const ACCOUNT = 'account';
export const ACCOUNT_KEY = 'account-key';
export const SHIELD_KEY = 'shield-key';
export const IS_USER = 'account-check';
export const PATIENT_LISTS = 'account-multiple';
export const DEFAULT_SEARCH = 'account-search-outline';
export const LINK_IDENTITIES = 'account-switch-outline';
export const WARNING = 'alert-circle-outline';
export const WARNING_DUE = 'alert';
export const PLAN_WARNING = 'clipboard-alert-outline';
export const ALERT_CIRCLE = 'alert-circle';
export const MISSING_COMPONENT = 'alert-circle-outline';
export const BED = 'bed';
export const CARE_PLAN_ASSET = 'alert-octagon';
export const CARE_PLAN_BARRIER = 'alert-octagon';
export const COLLAPSE_COMPONENT = 'arrow-down-drop-circle-outline';
export const EXPAND_QUADVIEW = 'fullscreen';
export const COLLAPSE_QUADVIEW = 'fullscreen-exit';
export const SURVEY_BUTTON_PART = 'arrow-right-bold';
export const CRISIS_PLAN_ARROW = 'arrow-right-bold-outline';
export const EXPAND_COMPONENT = 'arrow-right-drop-circle-outline';
export const SOCIAL_AT = 'at';
export const CONFIRMED_APPOINTMENT = 'calendar-check';
export const EDIT_APPOINTMENT = 'calendar-edit';
export const CUSTOM_DATE_RANGE = 'calendar-range';
export const UNCONFIRMED_APPOINTMENT = 'calendar-range';
export const CALENDAR_APPT = 'calendar-clock';
export const CHECK_CIRCLE_OUTLINE = 'check-circle-outline';
export const UNCHECKED_BOX = 'checkbox-blank-outline';
export const CHECKED_BOX = 'checkbox-marked';
export const FILE_UPLOADED = 'checkbox-marked-circle';
export const SCHEDULE_PRIORITY = 'checkbox-marked-circle';
export const CHEVRON_DOWN = 'chevron-down';
export const CHEVRON_RIGHT = 'chevron-right';
export const CHEVRON_LEFT = 'chevron-left';
export const CLOSE = 'close';
export const SAVE = 'content-save';
export const DELETE = 'delete';
export const ADVANCED_SEARCH = 'dots-horizontal';
export const EXPORT_DATA = 'download';
export const MAIL = 'email';
export const RESEND_MAIL = 'email-plus';
export const MANUAL_TRANSLATION = 'hand-coin';
export const CARE_PLAN_ACTION = 'exit-to-app';
export const PRINT = 'file';
export const FILE_DOCUMENT_EDIT = 'file-document-edit-outline';
export const OPEN_DETAILS = 'file-document';
export const DOWNLOAD_DOCUMENT = 'file-pdf-box';
export const DOWNLOAD_PREVIEW = 'file-powerpoint';
export const DOWNLOAD_FILE = 'file-download-outline';
export const SAVE_FILE = 'file-save';
export const OPEN_DOCUMENT = 'file-search-outline';
export const CANNOT_CLEAR = 'filter-outline';
export const CLEAR_FILTERS = 'filter-remove';
export const FLAGGED_MAIL = 'flag';
export const HELP = 'help-circle';
export const HISTORY = 'history';
export const PATIENT = 'human';
export const INFORMATION = 'information-outline';
export const LOCK = 'lock';
export const SEARCH = 'magnify';
export const CLEAR = 'cancel';
export const MARK_AS_UNREAD = 'mailbox';
export const MARK_AS_READ = 'mailbox-up-outline';
export const VETERAN = 'medal';
export const MENU = 'menu';
export const REMOVE = 'minus-circle-outline';
export const CARE_PLAN_NOTE = 'note';
export const OPEN_IN_NEW = 'open-in-new';
export const ATTACH_FILE = 'paperclip';
export const EDIT = 'pencil';
export const VIEW = 'eye-outline';
export const ADD_FILLED = 'plus-circle';
export const ADD = 'plus-circle-outline';
export const REFRESH = 'refresh';
export const SETTINGS = 'cog';
export const CARE_PLAN_GOAL = 'star';
export const STAR_OUTLINE = 'star-outline';
export const REQUIRED = 'asterisk';
export const CARE_PLAN_INTERVENTION = 'trending-up';
export const FILE_UPLOAD = 'upload';
export const DECEASED = 'diameter-variant';
export const PERFORM_ACTION = 'cursor-default-click-outline';
export const PHONE = 'phone-classic';
export const PHONE_OUTLINE = 'phone-outline';
export const PHONE_OUTGOING = 'phone-outgoing';
export const PHONE_CALL = 'phone-in-talk';
export const CHECKMARK = 'check';
export const MULTI_PAGE = 'plus-box-multiple';
export const SPLIT = 'content-cut';
export const ACCEPTING_PATIENTS = 'playlist-check';
export const EXCLAMATION = 'exclamation';
export const PAGE_FIRST = 'page-first';
export const PAGE_LAST = 'page-last';
export const SWAP = 'swap-horizontal';
export const ROTATE_RIGHT = 'rotate-right';
export const CALCULATE = 'calculator';
export const OWNER = 'human-greeting';
export const DUPLICATE = 'content-duplicate';
export const REDIRECT = 'directions-fork';
export const COLLAPSE_ARROW_LEFT = 'arrow-collapse-left';
export const COLLAPSE_ARROW_RIGHT = 'arrow-collapse-right';
export const FILE_UNDO = 'file-undo';
export const PERSON_ARROW_RIGHT = 'account-arrow-right-outline';
export const CARE_MANAGER = 'clipboard-pulse-outline';
export const DELEGATED_ENTITY = 'account-group-outline';
export const CHART_GANTT = 'chart-gantt';
export const SHIELD_UNLOCK = 'shield-lock-open-outline';
export const SHIELD_LOCK = 'shield-lock-outline';
export const CLOCK_PLUS_OUTLINE = 'clock-plus-outline';

//Activity Asset Icons
export const ACTIVITY_ASSET_CALL = 'phone-classic';
export const ACTIVITY_ASSET_CALL_FAIL = 'phone-off';
export const ACTIVITY_ASSET_MAIL = 'mailbox-outline';
export const ACTIVITY_ASSET_UPLOAD = 'file-document-outline';
export const ACTIVITY_ASSET_AUTH_MENU = 'message-lock-outline';
export const ACTIVITY_ASSET_TASKS = 'tray-full';
export const ACTIVITY_ASSET_VISIT_NOTE = 'clipboard-text-outline';
export const ACTIVITY_ASSET_DEFAULT = 'file-document';
export const ACTIVITY_ASSET_WAITLIST = 'text-box-check-outline';

//Alert Icons
export const ALERT_NONE = 'information';
export const ALERT_CLEARED = 'thumb-up-outline';
export const ALERT_LOW = 'speedometer-slow';
export const ALERT_MED = 'speedometer-medium';
export const ALERT_HIGH = 'speedometer';
export const ALERT_ERROR = 'close-octagon-outline';
export const ALERT_PREVENT = 'thumb-down-outline';
export const ALERT_OUTLINE = 'alert-outline';

/* Priority icons */
export const PRIORITY_LOW = 'signal-cellular-1';
export const PRIORITY_NORMAL = 'signal-cellular-2';
export const PRIORITY_HIGH = 'signal-cellular-3';
