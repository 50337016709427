/*eslint-disable no-console*/
import { useEffect, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ReplaySubject, merge as mergeObservables, from, NEVER } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  merge,
  pluck,
  startWith,
  withLatestFrom,
  tap,
  finalize,
  take,
  ignoreElements,
  catchError,
} from 'rxjs/operators';
import { useMenuNode } from '../contexts/XeMenuNodeContext';
import { pluck as fpPluck, isShallowEqual } from '../fp/object';
import { combineReducers, identity } from '../fp/fp';
import { toCacheKeyFromRequest } from '../service/serviceCache';
import { EMPTY_OBJECT } from '../constants';
import { toConfigReducer, toHeaderReducer } from '../service/data';
import {
  headerCleanupReducer,
  toCombinedRequestReducer,
} from '../service/toRequestFns';
import { isFunction } from '../fp/pred';

export const FEATURE_START = 'ejs/featureStart';
export const FEATURE_RESUME = 'ejs/featureResume';
export const FEATURE_PAUSE = 'ejs/featurePause';

export const ADD_FEATURE = 'ADD_FEATURE';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
const CONTEXT_UPDATE = 'CONTEXT_UPDATE';
const ALL_PROPS = 'props/allProps';

const MINIMUM_SCREEN_TIME_MS = 500;

const toDefaultEpic = () => NEVER;

const toActiveFeatureCache = () => {
  const activeFeatures = {};
  const toFeatureKey = (value) => `${value.componentPath}-${value.name}`;
  const get = (value) => {
    return activeFeatures[toFeatureKey(value)];
  };
  const register = (value) => {
    activeFeatures[toFeatureKey(value)] = Date.now();
  };
  const remove = (value) => {
    activeFeatures[toFeatureKey(value)] = undefined;
  };
  return {
    get,
    register,
    remove,
  };
};

const {
  get: getActiveFeatureTime,
  register: addActiveFeature,
  remove: removeActiveFeature,
} = toActiveFeatureCache();

//TODO This can be further optimized if I think about how the initial reduce is used
export const toReducerAt = (...path) => {
  return (reducerFn) => {
    return path.reverse().reduce(
      (accFn, prop) =>
        (state = {}, action) => {
          const { [prop]: value, ...rest } = state;

          const newValue = accFn(value, action);
          //Shortcut to ensure we keep reference identity when possible
          if (newValue === value) return state;

          return {
            [prop]: newValue,
            ...rest,
          };
        },
      reducerFn
    );
  };
};

const contextReducer = (state = {}, action) => {
  const { type, value } = action;

  if (type !== CONTEXT_UPDATE) return state;

  const { key, observation } = value;

  const { contexts = {} } = state;

  const { [key]: currentContextValue, ...restContexts } = contexts;

  if (currentContextValue === observation) {
    return state;
  }

  return {
    ...state,
    contexts: {
      ...restContexts,
      [key]: observation,
    },
  };
};

const toCallbackSideEffect = (action$, props$, callbackFns = []) => {
  if (callbackFns == undefined || callbackFns.length == 0) return NEVER;

  return action$.pipe(
    filter(({ type }) => type !== ALL_PROPS && type !== CONTEXT_UPDATE),
    withLatestFrom(props$.pipe(startWith(undefined))),
    tap(([action, propsValues]) => {
      if (propsValues) {
        const toBeInvoked = callbackFns.filter(({ predicate }) =>
          predicate(propsValues, action)
        );

        toBeInvoked.forEach(({ fn: callbackFn }) => {
          callbackFn(propsValues, action);
        });
      }
    }),
    ignoreElements()
  );
};

const useShallowComparison = (value) => {
  const ref = useRef();

  if (!isShallowEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const useScopedReducer = (
  identifier,
  reducer,
  toEpic,
  externalsState$s,
  callbackFns = undefined
) => {
  const scopedNodecontext = useMenuNode();

  const { componentPath } = scopedNodecontext;
  //const last = useRef({});

  const dispatch = useDispatch();

  const props$ = useMemo(() => {
    return new ReplaySubject(1);
  }, []);

  const updatedExternalsState$s = useShallowComparison(externalsState$s);
  const context$ = useMemo(() => {
    if (!updatedExternalsState$s) {
      return NEVER;
    }
    const arr = Object.entries(updatedExternalsState$s).reduce(
      (acc, [origKey, obs$]) => {
        if (!obs$) return acc;
        return [
          ...acc,
          obs$.pipe(
            distinctUntilChanged(),
            map((observation) => {
              const key = origKey.slice(0, -1);
              return {
                type: CONTEXT_UPDATE,
                value: {
                  key,
                  observation,
                },
                scope: componentPath,
              };
            })
          ),
        ];
      },
      []
    );

    return mergeObservables(...arr);
  }, [componentPath, updatedExternalsState$s]);

  useEffect(() => {
    const toScopedEpic$ = (action$, state$, dependencies) => {
      const scopedAction$ = componentPath
        ? action$.pipe(
            filter(({ scope }) => {
              return scope === componentPath || !scope; //intentionally covering places where scope is undefined or GLOBAL_SCOPE (which right now is an empty string)
            })
          )
        : action$;

      const scopedState$ = componentPath
        ? state$.pipe(
            pluck(componentPath),
            map((state) => state || {})
          )
        : state$;

      const epicStartOrResume$ = state$.pipe(
        pluck('removedFeatures', componentPath),
        take(1),
        map((wasRemoved) => ({
          type: wasRemoved ? FEATURE_RESUME : FEATURE_START,
        }))
      );

      const { streams } = dependencies;
      const { toServiceErrors$ } = streams;
      const serviceErrors$ = toServiceErrors$();

      const localDependencies = {
        ...dependencies,
        props$,
        streams: {
          serviceErrors$,
          ...streams,
        },
      };

      const changedProps$ = props$.pipe(
        distinctUntilChanged(isShallowEqual),
        map((value) => {
          return {
            type: ALL_PROPS,
            value,
            scope: componentPath,
          };
        })
      );

      const toDefaultedEpic = toEpic || toDefaultEpic;
      return mergeObservables(
        toDefaultedEpic(scopedAction$, scopedState$, localDependencies),
        toCallbackSideEffect(scopedAction$, props$, callbackFns, identifier)
      ).pipe(
        merge(epicStartOrResume$, context$, changedProps$),
        map((action) => {
          const { type, scope, ...rest } = action;
          if (!type) console.error('Action received without a type');

          if (!componentPath || scope !== undefined) {
            return action;
          }

          return {
            ...rest,
            type,
            scope: componentPath,
          };
        }),
        finalize(() =>
          dispatch({
            type: FEATURE_PAUSE,
            scope: componentPath,
          })
        )
      );
    };

    const toDeepReducer = toReducerAt(componentPath)(
      //we need to avoid setting props or context up front or any reducers that check for undefined
      //will never get that chance as the props and context will make it through
      combineReducers(reducer, contextReducer)
    );

    const scopedReducer = (state, action) => {
      const { scope } = action;

      //Shortcut if this isn't for us or isn't global.... move on (this needs to move to a when statement eventually to be optimized)
      if (scope && scope !== componentPath) return state;

      return toDeepReducer(state, action);
    };

    const value = {
      reducer: componentPath ? scopedReducer : reducer,
      toEpic: toScopedEpic$,
      identifier,
      name: identifier.toString(),
      componentPath,
    };
    //console.log(identifier.toString());
    dispatch({ type: ADD_FEATURE, value, scope: componentPath });

    if (getActiveFeatureTime(value))
      console.warn(`Second component has been build at path ${componentPath}`);
    addActiveFeature(value);

    //console.log(`Adding ${value.identifier.toString()}`);
    //debugger;
    return function cleanup() {
      // if (identifier === 'XeApplication') {
      //   debugger
      // }
      //console.log(`Removing ${identifier}`);
      dispatch({ type: REMOVE_FEATURE, value, scope: componentPath });
      if (!getActiveFeatureTime(value)) {
        console.warn(
          `Attempted to clean up featurePath that doesn't exist: ${componentPath}`
        );
      }
      const timeOnScreen = Date.now() - getActiveFeatureTime(value);
      if (timeOnScreen < MINIMUM_SCREEN_TIME_MS) {
        console.warn(`Component ${value.name} at path ${componentPath} was removed after ${timeOnScreen}ms
          this may be due to a feature re-rendering multiple times.
        `);
      }
      removeActiveFeature(value);
      //console.log(`Removing ${value.identifier.toString()}`);
      //debugger;
    };
  }, [
    dispatch,
    props$,
    context$,
    reducer,
    toEpic,
    callbackFns,
    componentPath,
    identifier,
  ]);

  // const o = last.current[componentPath] || {};
  // const tmp = {
  //   dispatch,
  //   props$,
  //   context$,
  //   reducer,
  //   toEpic,
  //   callbackFns,
  //   componentPath,
  //   identifier,
  // };
  // Object.entries(tmp).forEach(([k, v])=>{
  //   console.log(`${componentPath}.${k} ${v===o[k]}`);
  // })

  // if (componentPath==='THRASYS_CCM/XeApplication:240000' && tmp?.dispatch !== last?.current['THRASYS_CCM/XeApplication:240000']?.dispatch) {
  //   debugger
  // }
  // last.current[componentPath] = tmp;

  const propsSideEffect = useCallback(
    (props) => {
      props$.next({ ...props, children: undefined });
    },
    [props$]
  );

  return {
    propsSideEffect,
    componentPath,
  };
};

export const useScopedDispatch = () => {
  const { componentPath } = useMenuNode();

  const dispatch = useDispatch();
  const scopedDispatch = useMemo(() => {
    return (action = {}) => {
      dispatch({ ...action, scope: componentPath });
    };
  }, [componentPath, dispatch]);

  return scopedDispatch;
};

/**
 *
 * @param {string} type action type to dispatch
 * @return {function} callback function
 */
export const useScopedValueDispatch = (type) => {
  const dispatch = useScopedDispatch();
  return useCallback((value) => dispatch({ type, value }), [dispatch, type]);
};

export const useScopedSelector = (selector, equalityFn = shallowEqual) => {
  const { componentPath } = useMenuNode();

  const pathSelector = useMemo(() => {
    const plucker = fpPluck(componentPath);
    return plucker;
  }, [componentPath]);

  const scopedSelector = useSelector((state) => {
    const atPath = pathSelector(state);
    return selector(atPath);
  }, equalityFn);

  return scopedSelector;
};

//Super happy to be duplicating this code here.... but without (more) murders its the best I can do...
//There are really bad assumptions being made all over that we are going to need to unwind
export const withMoreReducers =
  (invoker) =>
  (...lastReducers) => {
    if (lastReducers.some((fn) => !isFunction(fn))) {
      console.error(
        'Additional data passed as reducer to request function. Are you missing a level of arity?'
      );
    }
    return (...nextReducers) => {
      if (nextReducers.length) {
        return withMoreReducers(invoker)(...lastReducers, ...nextReducers);
      }

      return invoker(...lastReducers);
    };
  };

const pluckRequestPart =
  (config, fullRequest = false) =>
  (response) => {
    if (fullRequest) {
      if (response instanceof Blob) {
        return {
          blob: response,
          request: config,
        };
      }
      return { ...response, request: config };
    }
    return !!response && !!response.results ? response.results : response;
  };

export const toLegacyBaseRequestFn = (
  queryClient,
  requestConfigFn,
  requestInvoker
) => {
  const defaultOptions = queryClient.getDefaultOptions();
  const {
    queries: {
      staleTime: globalStaleTime = 0,
      retry: globalRetry = false,
    } = {},
  } = defaultOptions;

  const proxyInvoker = (...reducers) => {
    const configFn = reducers.length
      ? requestConfigFn(...reducers)(/*no more reducers*/)
      : requestConfigFn(/*no more reducers*/);

    return (additionalData = EMPTY_OBJECT) => {
      const {
        fullRequest = false, //this is intended to die over time and all responses will be full request
        responseType,
        headers = {},
      } = additionalData;

      const controller = new AbortController();
      const signal = controller.signal;
      let abortable = true;
      const unsetAbortable = () => {
        abortable = false;
      };

      const toConfigWithAdditional = toCombinedRequestReducer(
        responseType ? toConfigReducer('responseType')(responseType) : identity,
        ...Object.entries(headers).map(([key, value]) =>
          toHeaderReducer(key)(value)
        ),
        headerCleanupReducer
      );

      const awaitingFinalConfig = requestInvoker(additionalData);
      return (config) => {
        const finalConfig = toConfigWithAdditional(configFn(config));
        const selector = pluckRequestPart(finalConfig, fullRequest);
        const queryKey = toCacheKeyFromRequest(finalConfig);

        const {
          method = 'GET',
          isBackground = false,
          staleTime: providedStaleTime,
          retry = globalRetry,
        } = finalConfig;

        //SYNUI-7226.... we need to, by default ensure that we do not set a cache time for not GET requests.... can be overriden
        const defaultStaleTime = method === 'GET' ? globalStaleTime : 0; //SYNUI-4734;
        const staleTime = providedStaleTime ?? defaultStaleTime;

        const promise = queryClient
          .fetchQuery(
            queryKey,
            () => {
              return awaitingFinalConfig({ ...finalConfig, signal });
            },
            {
              isBackground,
              staleTime,
              retry,
            }
          )
          .then(selector);

        return from(promise).pipe(
          catchError(() => NEVER),
          tap(unsetAbortable, unsetAbortable, unsetAbortable),
          finalize(() => {
            if (abortable) {
              console.log(`Aborting ${finalConfig.url}`);
              //if we are here that means we are being unsubscribed from before we get any result
              controller.abort();
            }
          })
        );
      };
    };
  };

  return withMoreReducers(
    proxyInvoker
  )(/* do not prime it with any last reducers*/);
};
