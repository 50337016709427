const isFrameLoader = (window, name) => {
  try {
    return window.self !== window.top && window.name === name;
  } catch (e) {
    return true;
  }
};

export const toDeployMode = (window, name) => {
  return isFrameLoader(window, name) ? 'FrameLoader' : 'Application';
};

const toAuthProvider = (window) => {
  const [body] = window.document.getElementsByTagName('body') || [];
  const { dataset: { authProvider = 'xnet' } = {} } = body;

  return authProvider;
};

const toServerBaseUrl = (window) => {
  const origin = window.location.origin;
  return origin;
};

export const toEnvironment = (window, name) => {
  return {
    deployMode: toDeployMode(window, name),
    authProvider: toAuthProvider(window),
    toWindow: () => window,
    serverBaseUrl: toServerBaseUrl(window),
  };
};

export default toDeployMode;
