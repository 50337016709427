import XeAuth0Authenticator from '../XeAuth0Authenticator';
import { useEffect$ } from '../../hooks/useEffect$';
import { EMPTY_OBJECT } from '../../constants';
import { toXNetLogin } from './xnetLogin';
import toXnetAuthClient from '../../auth/xnetAuthClient';
import { from } from 'rxjs';

const XnetConfigAuthenticator = (props) => {
  const {
    universe = EMPTY_OBJECT,
    onAuthClient,
    requestInvoker,
    requestConfigFn,
  } = props;

  const { serverConfig = EMPTY_OBJECT } = universe;

  useEffect$(() => {
    const { user, pass, xeAcceptTerms } = serverConfig;

    return from(
      toXNetLogin(
        requestInvoker,
        requestConfigFn,
        user,
        pass,
        xeAcceptTerms
      ).then((authorization) => {
        onAuthClient(toXnetAuthClient(user, authorization, false));
      })
    );
  }, [serverConfig, requestInvoker, requestConfigFn]);

  return null;
};

export const XeDevelopmentAuthenticator = (props) => {
  const {
    universe: {
      serverConfig: { authProvider = 'xnet' } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
  } = props;

  if (authProvider === 'xnet') {
    return <XnetConfigAuthenticator {...props} />;
  } else if (authProvider === 'auth0') {
    return <XeAuth0Authenticator {...props} />;
  }

  return null;
};

export default XeDevelopmentAuthenticator;
