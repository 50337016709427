import { useMemo, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { b64_md5 } from "../auth/md5";
import { EMPTY_OBJECT } from "../constants";
import { 
  useEnterprise,
  XeEnterpriseInitialContext
} from "../contexts/XeEnterpriseContext";
import { isFunction } from "../fp/pred";
import { fromLocalStorage, toLocalStorage } from "../local/localStorage";
import { get, set } from '../fp/object';

const toInitialLocalState = (stateKey, key, defaultState) => {
  if (stateKey == undefined) {
    return {
      [key]: defaultState
    };
  }

  return fromLocalStorage(stateKey) ?? {
    [key]: defaultState
  };
};

export const useLocalStorage = (key, initialState) => {
  const queryClient = useQueryClient();
  const enterprise = useEnterprise();

  if (enterprise === XeEnterpriseInitialContext) {
    console.error('useLocalStorage uses user information and is only viable to use within an XeEnterpriseContext');
  }

  const defaultState = useRef(initialState);

  const {
    userData = EMPTY_OBJECT
  } = enterprise;  

  const stateKey = useMemo(()=>{
    const {
      Username = '',
      EnterpriseID = ''
    } = userData;

    if (userData === EMPTY_OBJECT) return undefined;

    const src = `${EnterpriseID}/${Username}`;
    return `xnetState/${b64_md5(src)}`;
  }, [userData]);

  const { data } = useQuery(
    [stateKey], 
    ()=>{
      return Promise.resolve(fromLocalStorage(stateKey));
    }, {
      initialData: toInitialLocalState(stateKey, key, defaultState.current),
      isLocalStorage: true,
    }
  );

  const { mutate } = useMutation(value=>{
    toLocalStorage(stateKey, value);
    return Promise.resolve(value);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([stateKey]);
    }
  });

  const api = useMemo(()=>{
    const currentValue = data ?? toInitialLocalState(stateKey, key, defaultState.current);
    const setter = (valOrFn)=>{
      if (isFunction(valOrFn)) {
        return mutate(
          set(
            key, 
            valOrFn(get(key, currentValue)), 
            currentValue
          )
        );
      } 

      return mutate(
        set(key, valOrFn, currentValue)
      );
    };

    return [
      get(key, currentValue),
      setter
    ];
  }, [stateKey, key, data, mutate]);

  return api;
};