import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants';
import { useEnterprise } from '../contexts/XeEnterpriseContext';
import { useMenuNode } from '../contexts/XeMenuNodeContext';
import { toMenuNodesFromPath } from '../contexts/XeMenuNodeContext/components';

export const useRouteLocationDetails = () => {
  const { pathname } = useLocation();

  const { XeAppMenuNode: { MenuNodeID: parentMenuNodeID } = EMPTY_OBJECT } =
    useMenuNode();

  const { userData: { XeAppMenus = EMPTY_ARRAY } = EMPTY_OBJECT } =
    useEnterprise();

  return useMemo(() => {
    const resolved = toMenuNodesFromPath(
      decodeURIComponent(pathname),
      XeAppMenus
    );

    if (parentMenuNodeID === undefined) {
      return resolved;
    }

    //Not using strict equality intentionally, number versus string representation
    const parentIndex = resolved.findIndex(
      (XeAppMenuNode) => XeAppMenuNode?.MenuNodeID == parentMenuNodeID
    );

    if (parentIndex === -1) {
      //Unfortunately, it seems as though that parent is not in the rendered hierarchy
      return EMPTY_ARRAY;
    }

    return resolved.slice(parentIndex + 1);
  }, [pathname, XeAppMenus, parentMenuNodeID]);
};
