export const APP_READY = 'XeAppContainer/appReady';
export const REQUEST_INVOKER = 'XeAppContainer/requestInvoker';
export const REQUEST_CONFIG_FN = 'XeAppContainer/requestConfigFn';
// Action used from the outside to pass a notification in
export const NOTIFICATION = 'XeAppContainer/notification';
// Action used by XeAppContainer to add notification to its store
export const ADD_NOTIFICATION = 'XeAppContainer/addNotification';
// Action used by XeAppContainer to remove notification from its store
export const REMOVE_NOTIFICATION = 'XeAppContainer/removeNotification';

export const AUTH_CLIENT = 'XeAppContainer/authClient';
