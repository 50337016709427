import { DateTime } from 'luxon';

const DAYS_IN_WEEK = 7;

const toNumberInString = (s) => +s.match(/\d/g).join('');

const isFutureDateFromKey = (key) => key.includes('+');

const luxonUnitMap = {
  D: 'days',
  M: 'months',
  Y: 'years',
  // Weeks are not supported in luxon so we are mapping them to 7 days
  W: 'days',
};

const toDateTypeFromKey = (key) => {
  const timeKey = key.slice(-1).toUpperCase();
  return luxonUnitMap[timeKey];
};

const toValueFromKey = (key) => {
  const timeKey = key.slice(-1).toUpperCase();
  const timeNumber = toNumberInString(key);
  const timeDuration = timeKey === 'W' ? timeNumber * DAYS_IN_WEEK : timeNumber;
  if (isFutureDateFromKey(key)) {
    return timeDuration;
  }
  //If there is no '+' in key it returns the negative
  return timeDuration * -1;
};

const toDateFromKey = (key) => {
  const unit = toDateTypeFromKey(key);
  const quantity = toValueFromKey(key);
  // plus can take either a positive or negative quantity
  return DateTime.local().plus({ [unit]: quantity });
};

const toTimeRangeObjPastToNow = (key, fuzzyness) => (formatFn) => ({
  from: formatFn(toDateFromKey(key).startOf(fuzzyness)),
  to: formatFn(DateTime.local().endOf('day')),
});

const toTimeRangeObjNowToFuture = (key, fuzzyness) => (formatFn) => ({
  from: formatFn(DateTime.local().startOf('day')),
  to: formatFn(toDateFromKey(key).endOf(fuzzyness)),
});

// NOTE: Custom is still a bit of a special case. Starts with the keyword "Custom", and when set, will be returned as date strings following the FROM|TO format
export const CUSTOM = 'Custom';
export const CUSTOM_SEPARATOR = '|';
// NOTE: All is a bail case, where we always return undefined for the start and end
export const ALL = 'All';
export const TODAY = 'Today';
export const PAST_24H = '24H';

export const PAST_3D = '3D';
export const PAST_7D = '7D';
export const PAST_14D = '14D';

export const PAST_1M = '1M';
export const PAST_3M = '3M';
export const PAST_6M = '6M';

export const PAST_1Y = '1Y';
export const PAST_2Y = '2Y';
export const PAST_5Y = '5Y';

export const NEXT_7D = '+7D';

export const NEXT_2W = '+2W';
export const NEXT_3W = '+3W';
export const NEXT_4W = '+4W';
export const NEXT_6W = '+6W';

export const NEXT_1M = '+1M';
export const NEXT_2M = '+2M';

// Generally, the "fuzziness" value is "day", but in the case of the years it does switch from month to year "fuzziness".
// Keeping this map around to handle that, or any other special cases that come up.
const rangeYearKeyMap = {
  [PAST_1Y]: toTimeRangeObjPastToNow('1Y', 'month'),
  [PAST_2Y]: toTimeRangeObjPastToNow('2Y', 'month'),
  [PAST_5Y]: toTimeRangeObjPastToNow('5Y', 'year'),
};

export const toDateRangeFromKey = (key, formatFn) => {
  if (key === ALL) return { from: undefined, to: undefined };

  if (key === TODAY) {
    return {
      from: formatFn(DateTime.local().startOf('day')),
      to: formatFn(DateTime.local().endOf('day')),
    };
  }

  if (key.includes(CUSTOM_SEPARATOR)) {
    const [from, to] = key.split(CUSTOM_SEPARATOR);

    return {
      from,
      to,
    };
  }

  if (key === PAST_24H) {
    return {
      from: formatFn(DateTime.local().minus({ days: 1 }).startOf('hour')),
      to: formatFn(DateTime.local().endOf('hour')),
    };
  }

  // Handles special "fuzzyness" cases
  const toTimeRangeObj = rangeYearKeyMap[key];
  if (toTimeRangeObj) return toTimeRangeObj(formatFn);

  if (key.includes('+')) return toTimeRangeObjNowToFuture(key, 'day')(formatFn);

  return toTimeRangeObjPastToNow(key, 'day')(formatFn);
};

// NOTE: While we can use constants exported from this file now to configure date ranges on the fly,
// these are the handful of configurations we do actually use in multiple places
export const OVER_PAST_MONTH = [TODAY, PAST_7D, PAST_14D, PAST_1M, CUSTOM];
export const OVER_PAST_SIX_MONTHS = [
  TODAY,
  PAST_7D,
  PAST_1M,
  PAST_6M,
  ALL,
  CUSTOM,
];
export const OVER_PAST_YEAR = [PAST_1M, PAST_3M, PAST_6M, PAST_1Y, CUSTOM];
export const OVER_PAST_YEAR_WITH_ALL = [
  PAST_1M,
  PAST_3M,
  PAST_6M,
  PAST_1Y,
  ALL,
  CUSTOM,
];
export const OVER_PAST_SEVERAL_YEARS = [PAST_1Y, PAST_2Y, PAST_5Y, ALL, CUSTOM];
export const OVER_NEXT_MONTH = [TODAY, NEXT_7D, NEXT_2W, NEXT_1M, ALL, CUSTOM];
