import { ofType } from 'redux-observable';
import {
  tap,
  mergeMap,
  takeUntil,
  filter,
  ignoreElements,
  map,
  delay,
  merge,
} from 'rxjs/operators';

import { of, empty } from 'rxjs';

import {
  ADD_FEATURE,
  REMOVE_FEATURE,
  withMoreReducers,
} from '../../hooks/scopedReducer';

import { pipe } from '../../fp/fp';

import {
  REQUEST_CONFIG_FN,
  REQUEST_INVOKER,
  NOTIFICATION,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from './actions';

import { combineEpics } from 'redux-observable';

import {
  toHeaderReducer,
  toContentTypeHeaderReducer,
  toRequestInvoker,
  headerCleanupReducer,
} from '../../service/toRequestFns';

import uniqid from 'uniqid';

import { DEFAULT_ALERT_TIME, ERROR_NOTIFICATION } from '../../constants';
import {
  HEADER_CONTENT_TYPE,
  HEADER_XE_APPLICATION,
  HEADER_XE_TIMEZONE,
} from '../../service/constants';

const injectedEpics$ = (action$, state$, dependencies) => {
  const {
    environmentEpics: { appReady$ },
  } = dependencies;

  return combineEpics(appReady$)(action$, state$, dependencies);
};

const enterpriseEpic$ = (action$, state$, dependencies) => {
  return action$.pipe(
    ofType(ADD_FEATURE),
    mergeMap(
      ({ value: { toEpic, name, reducer, localDependencies = {} } } = {}) => {
        //Take this epic until we remove the reducers associated, then its time to go!
        const updatedDependencies = { ...dependencies, ...localDependencies };
        const proxidedDependencies = updatedDependencies;
        //Passing these into the 4th argument for universe for now so that we can support older code
        //TODO remove this as soon as the universe argument is removed from older epics
        return toEpic(
          action$,
          state$,
          proxidedDependencies,
          proxidedDependencies
        ).pipe(
          takeUntil(
            action$.pipe(
              ofType(REMOVE_FEATURE),
              filter(
                ({ value: { reducer: removedReducer } } = {}) =>
                  reducer === removedReducer
              )
              // tap(x=>{
              //   console.log('removed');
              // })
            )
          )
        );
      }
    )
  );
};

// eslint-disable-next-line no-unused-vars
const actionDebug$ = (action$, state$, dependencies) => {
  return action$.pipe(
    tap((action) => {
      //console.log(action);
    }),
    ignoreElements()
  );
};

// eslint-disable-next-line no-unused-vars
const stateDebug$ = (action$, state$, dependencies) => {
  return state$.pipe(
    tap((state) => {
      //console.log(state);
    }),
    ignoreElements()
  );
};

// This function validates that headers are appropriate for the request.
// 1. Based on the 'method' value, default headers may be used.
//    - A type of PUT or POST will automatically use a Content-Type of 'application/json'.
const contentTypeMap = {
  POST: { [HEADER_CONTENT_TYPE]: 'application/json' },
  PUT: { [HEADER_CONTENT_TYPE]: 'application/json' },
};

const toRequestInvoker$ = (action$, state$, dependencies) => {
  const {
    streams: { toServiceErrors$ },
    environment: { serverBaseUrl } = {},
    serverConfig: { pathRoot = '/Synergy' } = {},
  } = dependencies;

  const finalPathRoot =
    pathRoot === '/Synergy' ? `${serverBaseUrl}${pathRoot}` : pathRoot;

  return of(finalPathRoot).pipe(
    map((pathRoot) => {
      return {
        type: REQUEST_INVOKER,
        value: toRequestInvoker({
          serviceErrors$: toServiceErrors$(),
          pathRoot: pathRoot,
        }),
      };
    })
  );
};

const toBaseRequestConfigFn$ = (action$, state$, dependencies) => {
  const { APP_NAME: appName } = dependencies;

  const toRequestConfigFn = () => {
    const initialReducers = [
      toHeaderReducer(HEADER_XE_APPLICATION)(appName),
      toHeaderReducer(HEADER_XE_TIMEZONE)(
        Intl.DateTimeFormat().resolvedOptions().timeZone
      ),
      toContentTypeHeaderReducer(contentTypeMap),
    ];

    return withMoreReducers((...reducers) => {
      return (s0 = {}) => pipe(...reducers, headerCleanupReducer)(s0);
    })(...initialReducers);
  };

  return of({
    type: REQUEST_CONFIG_FN,
    value: toRequestConfigFn(),
  });
};

const toDisplayErrorFn = (error = {}) => {
  const { message, errorCode } = error;

  const defaultErrorMessage = message || 'Service request failed';
  if (errorCode === undefined) {
    return () => defaultErrorMessage;
  }

  const key = `error_${errorCode}`;
  return (labels) => {
    const errorLabel = labels[key];
    if (errorLabel !== key) {
      return errorLabel;
    }

    return defaultErrorMessage;
  };
};

const toServiceErrorNotification$ = (action$, state$, dependencies) => {
  const {
    streams: { toServiceErrors$ },
  } = dependencies;

  return toServiceErrors$().pipe(
    map((error = {}) => {
      return {
        type: NOTIFICATION,
        value: {
          style: ERROR_NOTIFICATION,
          toMessage: toDisplayErrorFn(error),
          timeout: DEFAULT_ALERT_TIME,
        },
      };
    })
  );
};

export const toAddNotification$ = (action$, state$, dependencies) => {
  const {
    streams: { toNotifications$ },
  } = dependencies;

  return action$.pipe(
    ofType(NOTIFICATION),
    merge(toNotifications$()),
    map(({ value }) => {
      return {
        type: ADD_NOTIFICATION,
        value: {
          ...value,
          // We add a unique id before adding it to the store so we can differentiate between them
          // On delete we are filtering out this specific id
          id: uniqid(),
        },
      };
    })
  );
};

export const toRemoveNotificationsOnTimeout$ = (action$) => {
  return action$.pipe(
    ofType(ADD_NOTIFICATION),
    mergeMap(({ value }) => {
      const { timeout } = value;
      if (!timeout) {
        return empty();
      }
      return of(value).pipe(delay(timeout));
    }),
    map((value) => ({
      type: REMOVE_NOTIFICATION,
      value,
    }))
  );
};

export default [
  enterpriseEpic$,
  injectedEpics$,
  toRequestInvoker$,
  toBaseRequestConfigFn$,
  toServiceErrorNotification$,
  toAddNotification$,
  toRemoveNotificationsOnTimeout$,
  //actionDebug$,
  //stateDebug$,
];
