import isObject from 'lodash/isObject';
import ldget from 'lodash/get';
import castPath from 'lodash/_castPath';

const identity = (x) => x;

export const mapValues = (o, f = identity, keySelector = Object.keys) => {
  const f2 = (k) => f(o[k], k, o);
  const r = (acc, k) => {
    acc[k] = f2(k);
    return acc;
  };
  return keySelector(o).reduce(r, {});
};

export const filter = (o, p, keySelector = Object.keys) => {
  const p2 = (k) => p(o[k], k, o);
  const keys = keySelector(o).filter(p2);
  const r = (acc, k) => {
    acc[k] = o[k];
    return acc;
  };
  return keys.reduce(r, {});
};

export const reduce = (o, f = identity, acc = {}, iteratee = Object.keys) => {
  const f2 = (acc, k) => f(acc, o[k], k, o);
  return iteratee(o).reduce(f2, acc);
};

const defaultObjectCreator = (nsValue, key, nsObject) => {
  if (Array.isArray(nsObject)) {
    const newArray = [...nsObject];
    newArray[key] = nsValue;
    return newArray;
  }

  return {
    ...nsObject,
    [key]: nsValue,
  };
};

const internalSet = (
  props,
  value,
  object0,
  customizer = defaultObjectCreator
) => {
  const [head, ...tail] = props;

  if (head === undefined) {
    return value;
  }

  const object = object0 !== undefined ? object0 : isNaN(head) ? {} : [];

  const childValue = internalSet(tail, value, object[head], customizer);

  //bail early if we get the same result
  return childValue === object[head]
    ? object
    : customizer(childValue, head, object);
};

export const set = (path, value, object, customizer) => {
  const segments = castPath(path, object);
  return internalSet(segments, value, object, customizer);
};

export const put =
  (...props) =>
  (value) =>
  (object, customizer) => {
    return internalSet(props, value, object, customizer);
  };

export const get = (path, object) => {
  if (path === undefined) return object;
  //future revision needed
  return ldget(object, path);
};

const internalPlucker = (acc, key) => acc?.[key];
export const pluck = (...props) => {
  return (o) => props.reduce(internalPlucker, o);
};

export const isShallowEqual = (o1 = {}, o2 = {}) => {
  //simple optimization
  if (o1 === o2) return true;
  if (
    o1 === null ||
    o2 === null ||
    Object.keys(o1).length !== Object.keys(o2).length
  )
    return false;

  if (isObject(o1) && isObject(o2)) {
    return Object.entries(o1).every(([k, v]) => v === o2[k]);
  }

  if (Array.isArray(o1) && Array.isArray(o2)) {
    return o1.every((v, i) => v === o2[i]);
  }

  return false;
};
