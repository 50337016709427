import { useCallback } from 'react';
import { EMPTY_OBJECT } from '../constants';
import { useSystem } from '../contexts/XeSystemContext';

/**
 *
 * DO NOT USE THIS FUNCTION
 *
 * You should be using usePathNavigate or useNavigate
 *
 * This function is for _exactly_ one use case.... when we receive a callback from auth0
 * or another oAuth provider that has additional search parameters that we need to lose.
 * In that _one_ case, we recompose the URL without those search params so that the user
 * has a simpler and nicer looking URL.
 *
 **/
export function useAbsoluteNavigate() {
  const { environment: { toWindow } = EMPTY_OBJECT } = useSystem();
  const window = toWindow();

  const navigate = useCallback(
    (to = '', { replace = false } = {}) => {
      const { origin, pathname } = window.location;

      const path = `${origin}${pathname}#${to}`;
      if (replace) {
        window.location.replace(path);
      } else {
        window.location.assign(path);
      }
    },
    [window]
  );

  return navigate;
}
