import toString from 'lodash/toString';

// The HTML Named Code/Numeric Code to their Char Character are used to
// "sanatize"/unescape the user's text inputs so they won't accidentally
// copy and paste or type some bad data in certain place in the application.
// If they copy some of the text in the wrong place, they will fall out of
// compliance and will be fined. So, this must be done to correct user error.
// We basically copied lodash's unescape implementation but added more escape
// characters to suit our application's needs. We can always add more in the
// future.
// Example of special escape character we get from the unsanitized backend -
// &amp;amp; -> &amp; -> &
// The first "&amp;" becomes "&" for the next "amp;" so "&" + "amp;" = "&amp;" -> "&"
// This is the reason why in the Regex "&amp;amp;" comes before "&amp;". We sanitize it
// in one go so we don't do multiple sanitization to get to the final "&". Also, "&amp;"/"&"
// will always have to be last in the Regex or else it will mess with the other Regex as
// that "&" will conflict with how we start the matching the next Named Code/Numeric Code.
// SNET-853 (JCh)
const htmlUnescapes = {
  '&amp;lt;': '<',
  '&lt;': '<',
  '&#60;': '<',
  '&amp;gt;': '>',
  '&gt;': '>',
  '&#62;': '>',
  '&quot;': '"',
  '&#34;': '"',
  '&apos;': "'",
  '&#39;': "'",
  '&amp;amp;': '&',
  '&amp;': '&',
  '&#38;': '&',
};

const reEscapedHtml =
  /&(?:amp;lt|lt|#60|amp;gt|gt|#62|quot|#34|apos|#39|amp;amp|amp|#38|);/g;

function basePropertyOf(object) {
  return function (key) {
    return object == null ? undefined : object[key];
  };
}

export function unescape(string) {
  const verifiedString = toString(string);
  const unescapeHtmlChar = basePropertyOf(htmlUnescapes);
  const reHasEscapedHtml = RegExp(reEscapedHtml);
  return verifiedString && reHasEscapedHtml.test(string)
    ? verifiedString.replace(reHasEscapedHtml, unescapeHtmlChar)
    : verifiedString;
}
