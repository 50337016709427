import { useFactory } from '../../hooks/useFactory';
import { Provider } from 'react-redux';
import configureStore from './store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import DefaultView from './views/DefaultView';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DEFAULT_QUERY_STALE_TIME } from '../../constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_QUERY_STALE_TIME,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const XeAppContainer = ({ universe }) => {
  const combinedUniverse = useFactory(
    () => ({ ...universe, queryClient }),
    [universe, queryClient]
  );

  const store = useFactory(
    () => configureStore(combinedUniverse),
    [combinedUniverse]
  );

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <HashRouter basename="/">
          <DefaultView key="ApplicationContainer" universe={combinedUniverse} />
        </HashRouter>
      </QueryClientProvider>
    </Provider>
  );
};

export default XeAppContainer;
