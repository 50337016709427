import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { logout } from 'services/authentications/xe-authentications-svc';
import { EMPTY_OBJECT } from '../../../constants';
import { toAuthenticatedRequestInvoker } from '../../../contexts/XeAuthenticationContext';
import '../styles.css';

export default (props) => {
  const {
    universe: {
      environment: { authProvider, toWindow } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    authClient,
    requestConfigFn,
    requestInvoker,
  } = props;

  const queryClient = useQueryClient();

  useEffect(() => {
    if (authClient) {
      const {
        location: { origin = '', pathname = '' },
      } = toWindow();

      const requestFn = (config) => {
        const finalConfig = requestConfigFn()(config);
        const invoker = toAuthenticatedRequestInvoker(
          authProvider,
          authClient,
          requestInvoker
        );
        return invoker(/*No additional args*/)(finalConfig);
      };

      logout({}, requestFn).finally(() => {
        queryClient.clear();
        authClient.logout({
          returnTo: `${origin}${pathname}`,
        });
      });
    }
  }, [
    authClient,
    authProvider,
    toWindow,
    requestConfigFn,
    requestInvoker,
    queryClient,
  ]);

  return (
    <div className="error">
      <div className="header">
        <div className="logo">
          <img src="https://www.thrasys.com/img/thrasys-logo.svg" />
        </div>
        <h3 className="header__title">Thrasys</h3>
        <h3 className="header__title--sub">
          You are being logged out of this session.
        </h3>
      </div>
      <div className="main">
        <p>Logout in process....</p>
      </div>
      <div className="footer"></div>
    </div>
  );
};
