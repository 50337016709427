import { operate } from 'rxjs/internal/util/lift';
import { OperatorSubscriber } from 'rxjs/internal/operators/OperatorSubscriber';
import { innerFrom } from 'rxjs/internal/observable/innerFrom';
import { identity } from 'rxjs/internal/util/identity';
import { noop } from 'rxjs/internal/util/noop';
import { popResultSelector } from 'rxjs/internal/util/args';

export function combineWithLatestFrom(...inputs) {
  const project = popResultSelector(inputs);

  return operate((source, subscriber) => {
    const len = inputs.length;
    const values = Array.from({ length: len + 1 });
    const sourceIndex = 0;

    // An array of whether or not the other sources have emitted. Matched with them by index.
    let hasValue = values.map(() => false);
    // Flipped true when we have at least one value from all other sources and
    // we are ready to start emitting values.
    let ready = false;

    //When are we done acting like a combineLatest and begin acting like a withLatestFrom
    let combinePhaseComplete = false;

    // Other sources. Note that here we are not checking `subscriber.closed`,
    // this causes all inputs to be subscribed to, even if nothing can be emitted
    // from them. This is an important distinction because subscription constitutes
    // a side-effect.
    for (let i = 0; i < len; i++) {
      innerFrom(inputs[i]).subscribe(
        new OperatorSubscriber(
          subscriber,
          (value) => {
            const valueIndex = i + 1;
            values[valueIndex] = value;
            if (!ready && !hasValue[valueIndex]) {
              // If we're not ready yet, flag to show this observable has emitted.
              hasValue[valueIndex] = true;
              // Intentionally terse code.
              // If all of our other observables have emitted, set `ready` to `true`,
              // so we know we can start emitting values, then clean up the `hasValue` array,
              // because we don't need it anymore.
              (ready = hasValue.every(identity)) && (hasValue = null);
            }

            if (ready && !combinePhaseComplete) {
              combinePhaseComplete = true;
              //console.log(`${i} : ${values.join(' - ')}`)

              // We have at least one value from the other sources. Go ahead and emit.
              //console.log(project ? project(...values) : values)
              subscriber.next(project ? project(...values) : [...values]);
            }
          },
          // Completing one of the other sources has
          // no bearing on the completion of our result.
          noop
        )
      );
    }

    // Source subscription
    source.subscribe(
      new OperatorSubscriber(subscriber, (value) => {
        values[sourceIndex] = value;

        if (!ready && !hasValue[sourceIndex]) {
          // If we're not ready yet, flag to show this observable has emitted.
          hasValue[sourceIndex] = true;
          // Intentionally terse code.
          // If all of our other observables have emitted, set `ready` to `true`,
          // so we know we can start emitting values, then clean up the `hasValue` array,
          // because we don't need it anymore.
          (ready = hasValue.every(identity)) && (hasValue = null);
          if (ready) {
            combinePhaseComplete = true;
          }
        }

        if (ready) {
          //console.log(`src : ${values.join(' - ')}`)
          // We have at least one value from the other sources. Go ahead and emit.
          subscriber.next(project ? project(...values) : [...values]);
        }
      })
    );
  });
}
