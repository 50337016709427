import { useMemo } from 'react';
import { EMPTY_OBJECT } from '../constants';
import { useSystem } from '../contexts/XeSystemContext';

export const useSystemPaths = () => {
  const {
    environment: { serverBaseUrl } = EMPTY_OBJECT,
    serverConfig: { pathRoot = '/Synergy' } = EMPTY_OBJECT,
  } = useSystem();

  return useMemo(() => {
    return {
      serverBaseUrl,
      preliminaryPathRoot: pathRoot,
      pathRoot:
        pathRoot === '/Synergy' ? `${serverBaseUrl}${pathRoot}` : pathRoot,
    };
  }, [serverBaseUrl, pathRoot]);
};
