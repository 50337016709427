import { isNil } from '../fp/pred';
import { useMemo, useRef } from 'react';

const isActiveItem = (item = {}) => {
  if (item.hasOwnProperty('Active')) {
    const { Active } = item;

    return isNil(Active) || Active == true;
  }

  return true;
};

const defaultComparator = (a, b) => a === b;

/**
 * @typedef {T & { Active: boolean }} ActiveObject
 * @template T
 */

/**
 * Filters inactive items from `list` while preserving the `initialSelectedItem`
 * @param {Array<ActiveObject<T>>} list List of objects with an `Active` property
 * @param {ActiveObject<T>} [initialSelectedItem=] Item to persist in the filtered list. If not found in `list`, it will be omitted.
 * @param {(listItem: any, value: any) => boolean} [comparator=] Comparator function to define item equality, defaults to ===
 * @template T
 * @return {Array<ActiveObject<T>>}
 */
export const useActiveList = (
  list,
  initialValue,
  comparator = defaultComparator
) => {
  const initialValueRef = useRef(undefined);
  if (initialValueRef.current === undefined && !isNil(initialValue)) {
    initialValueRef.current = initialValue;
  }
  const memoizedInitialSelectedItemIndex = useMemo(() => {
    if (!list || !list.length) return -1;
    return list.findIndex((x) => {
      return comparator(x, initialValueRef.current);
    });
  }, [list, comparator]);

  const memoizedList = useMemo(() => {
    if (!list || !list.length) return [];
    if (memoizedInitialSelectedItemIndex === -1)
      return list.filter((item) => {
        return isActiveItem(item);
      });
    return list.filter((item, index) => {
      return memoizedInitialSelectedItemIndex === index || isActiveItem(item);
    });
  }, [memoizedInitialSelectedItemIndex, list]);

  return memoizedList;
};
