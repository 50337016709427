import { useCallback, useMemo, createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XeRefDataContext } from '../../../contexts/XeRefDataContext';
import { NotificationGroup } from '../../../components/NotificationGroup';
import { XeAuthenticationContext } from '../../../contexts/XeAuthenticationContext';
import { XeEnterpriseContext } from '../../../contexts/XeEnterpriseContext';
import { XeLabelContext } from '../../../contexts/XeLabelContext';
import { XeUserRightsContext } from '../../../contexts/XeUserRightsContext';
import toLoadingMask from '../../../rendering/toLoadingMask';
import { AUTH_CLIENT, REMOVE_NOTIFICATION, REQUEST_INVOKER } from '../actions';
import { useRoutes, useSearchParams } from 'react-router-dom';
import XeAccessErrorPage from '../../XeAccessErrorPage';
import XeLoggedOut from '../../XeLoggedOut';
import XeLoggingOut from '../../XeLoggingOut';
import { Outlet } from 'react-router-dom';
import { XeSystemContext } from '../../../contexts/XeSystemContext';
import {
  RootRouteFeature,
  useRemountWatcher,
} from '../../../contexts/XeMenuNodeContext/components';
import { XeAppPropertyContext } from '../../../contexts/XeAppPropertyContext';
import { useAbsoluteNavigate } from '../../../hooks/useAbsoluteNavigate';

const EV_LISTENER_OPTION_CAPTURE_PHASE = true;

/**
 * @param {Event} ev
 */
const captureKeyDownTrap = (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
};

const CapturingLoadingMask = ({ toAppDomRoot, toLoadingMaskRoot }) => {
  const LoadingMask = useMemo(() => {
    return toLoadingMask(toLoadingMaskRoot());
  }, [toLoadingMaskRoot]);

  return (
    <LoadingMask
      key="LoadingMask"
      onShow={() => {
        /**
         * @type {HTMLElement}
         */
        const appDomRoot = toAppDomRoot();
        appDomRoot.addEventListener(
          'keydown',
          captureKeyDownTrap,
          EV_LISTENER_OPTION_CAPTURE_PHASE
        );
      }}
      onHide={() => {
        /**
         * @type {HTMLElement}
         */
        const appDomRoot = toAppDomRoot();
        appDomRoot.removeEventListener(
          'keydown',
          captureKeyDownTrap,
          EV_LISTENER_OPTION_CAPTURE_PHASE
        );
      }}
    />
  );
};

const WithNotifications = ({ children }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(({ notifications }) => notifications);
  const onCloseNotification = useCallback(
    (id) => dispatch({ type: REMOVE_NOTIFICATION, value: { id } }),
    [dispatch]
  );

  return (
    <>
      {children}
      <NotificationGroup
        key="NotificationGroup"
        notifications={notifications}
        onCloseNotification={onCloseNotification}
      />
    </>
  );
};

const AuthPendingView = ({
  universe,
  requestInvoker,
  requestConfigFn,
  isAuthCallback,
}) => {
  const {
    staticFeatures: { authFeature: AuthFeature },
    toDomRoots: { toLoadingMaskRoot, toAppDomRoot },
    environment: { authProvider } = {},
  } = universe;

  const [searchParams] = useSearchParams();
  const returnRoute = searchParams.get('route') ?? '/';
  const navigate = useAbsoluteNavigate(); //this is crappy to do here... but holy crap does react router suck
  //so basically there is no way to get rid of the extra auth0 search params as react router is convinced
  //that it knows better than everyone else on the planet

  const dispatch = useDispatch();

  //All authentication epics have to provide an auth client for us
  const onAuthClient = useCallback(
    (client) => {
      dispatch({ type: AUTH_CLIENT, value: client });
      navigate(returnRoute, { replace: true });
    },
    [returnRoute, dispatch, navigate]
  );

  //Some authentication epics provide a whole new invoker
  const onRequestInvoker = useCallback(
    (client) => {
      dispatch({ type: REQUEST_INVOKER, value: client });
    },
    [dispatch]
  );

  return (
    <>
      <CapturingLoadingMask
        toAppDomRoot={toAppDomRoot}
        toLoadingMaskRoot={toLoadingMaskRoot}
      />
      <WithNotifications>
        <AuthFeature
          authProvider={authProvider}
          universe={universe}
          onAuthClient={onAuthClient}
          onRequestInvoker={onRequestInvoker}
          requestInvoker={requestInvoker}
          requestConfigFn={requestConfigFn}
          isAuthCallback={isAuthCallback}
        />
      </WithNotifications>
    </>
  );
};

const XeAppContainerView = ({
  universe,
  authClient,
  requestInvoker,
  requestConfigFn,
  //...rest
}) => {
  const {
    toDomRoots: { toLoadingMaskRoot, toAppDomRoot },
  } = universe;

  useRemountWatcher('XeAppContainerView');

  return (
    <>
      <CapturingLoadingMask
        toAppDomRoot={toAppDomRoot}
        toLoadingMaskRoot={toLoadingMaskRoot}
      />
      <XeAuthenticationContext
        key="XeAuthenticationContext"
        universe={universe}
        authClient={authClient}
        requestInvoker={requestInvoker}
        requestConfigFn={requestConfigFn}
      >
        <XeAppPropertyContext key="XeAppPropertyContext">
          <XeEnterpriseContext key="XeEnterpriseContext">
            <XeLabelContext key="XeLabelContext">
              <WithNotifications key="EnterpriseNotificationGroup">
                <XeRefDataContext key="XeRefDataContext">
                  <XeUserRightsContext key="XeUserRightsContext">
                    <RootRouteFeature>
                      <Outlet />
                    </RootRouteFeature>
                  </XeUserRightsContext>
                </XeRefDataContext>
              </WithNotifications>
            </XeLabelContext>
          </XeEnterpriseContext>
        </XeAppPropertyContext>
      </XeAuthenticationContext>
    </>
  );
};

export default ({ universe }) => {
  const authClient = useSelector(({ authClient }) => {
    return authClient;
  });

  const requestInvoker = useSelector(({ requestInvoker }) => {
    return requestInvoker;
  });

  const requestConfigFn = useSelector(({ requestConfigFn }) => {
    return requestConfigFn;
  });

  const TopLevelElement = useRoutes([
    {
      path: '/',
      element: (
        <XeAppContainerView
          universe={universe}
          authClient={authClient}
          requestInvoker={requestInvoker}
          requestConfigFn={requestConfigFn}
        />
      ),
    },
    {
      path: '/:enterpriseId/*',
      element: (
        <XeAppContainerView
          universe={universe}
          authClient={authClient}
          requestInvoker={requestInvoker}
          requestConfigFn={requestConfigFn}
        />
      ),
      // children: [
      //   {
      //     //path: '*',
      //     element: <RootRouteFeature />,
      //   },
      // ],
    },
    {
      path: '/authenticate',
      element: (
        <AuthPendingView
          universe={universe}
          authClient={authClient}
          requestInvoker={requestInvoker}
          requestConfigFn={requestConfigFn}
        />
      ),
    },
    {
      path: '/callback',
      element: (
        <AuthPendingView
          universe={universe}
          authClient={authClient}
          requestInvoker={requestInvoker}
          requestConfigFn={requestConfigFn}
          isAuthCallback={true}
        />
      ),
    },
    {
      path: '/denied',
      element: (
        <XeAccessErrorPage universe={universe} authClient={authClient} />
      ),
    },
    {
      path: '/logout',
      element: (
        <XeLoggingOut
          universe={universe}
          authClient={authClient}
          requestInvoker={requestInvoker}
          requestConfigFn={requestConfigFn}
        />
      ),
    },
    {
      path: '/loggedOut',
      element: <XeLoggedOut />,
    },
  ]);

  return createElement(
    XeSystemContext,
    {
      value: universe,
    },
    TopLevelElement
  );
};
