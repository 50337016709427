import { toAuth } from '../../auth/login';
import {
  getChallenge,
  login,
} from 'services/authentications/xe-authentications-svc';

import { neverCache } from '../../service/serviceCache';

export const toXNetLogin = (
  requestInvoker, 
  requestConfigFn,
  user,
  pass,
  xeAcceptTerms
) =>{
  const requestFn = (config) => {
    const finalConfig = requestConfigFn(neverCache)()(config);
    return requestInvoker(/*No additional args*/)(finalConfig);
  };
  const nonce = `N${Date.now()}`;

  return getChallenge(
    {
      LoginName: user,
      Nonce: nonce,
    },
    requestFn
  ).then((response) => {
    const { algorithm, userSalt, token, user, sessionID } = response;
    const authorization = toAuth({
      algorithm,
      userSalt,
      token,
      nonce,
      pass,
      user,
      sessionID,
    });

    return login(
      {
        authorization: `XNet ${authorization}`,
        xeAcceptTerms,
      },
      requestFn
    ).then(() => authorization);
  });
};