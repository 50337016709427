export const SHOULD_UPLOAD_FILES = 'files/shouldUploadFiles';
export const SHOULD_ADD_DOCUMENT = 'files/shouldAddDocument';
export const SHOULD_UPDATE_DOCUMENT = 'files/shouldUpdateDocument';
export const SHOULD_UPDATE_SCAN_DOCS_TO = 'files/shouldUpdateScanDocsTo';
export const SHOULD_DOWNLOAD_DOCUMENT = 'files/shouldDownloadDocument';
export const SHOULD_DOWNLOAD_REPORT = 'files/shouldGetReport';
export const SHOULD_DOWNLOAD_FILE = 'files/shouldDownloadFile';

export const DID_GET_SCAN_DOC_ID = 'files/didGetScanDocID';
export const DID_UPLOAD_FILES = 'files/didUploadFiles';
export const DID_PROMPT_FILE_DOWNLOAD = 'files/didPromptFileDownload';

export const DID_SAVE_FILE_SET = 'files/didSaveFileSet';
