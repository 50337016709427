import PropTypes from 'prop-types';
import { DateTime, Settings } from 'luxon';

Settings.throwOnInvalid = true;

/*
Date naming conventions:

With so many date types floating around, it is best to be as explict as possible when naming variables or parameters.
Here are some suggested naming conventions for various date formats.

Strings:

For a XS_DATE_FORMAT string in particular, use isoDateStr or dateStr.

For a XS_DATE_TIME_FORMAT string in particular, use isoDateTimeStr or dateTimeString

For a XE_LOCAL_DATE_TIME_FORMAT string in particular, use isoLocalDateTimeStr or localDateTimeStr

For any of the above server string formats, use isoString or isoStr.

For a string in a locale display format, use dateDisplayStr, or timeDisplayStr

Objects

For a luxon DateTime object, use luxonDateTime

For a JS Date object, use jsDate

*/

export const XS_DATE_FORMAT = `yyyy-MM-dd`;
export const XS_DATE_TIME_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSSZZZ`;
export const XE_LOCAL_DATE_TIME_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSS`;
export const DATE_NOW_TOKEN = 'NOW';
export const DATE_NOW_START_OF_DAY = 'NOW_DAY_START';

const XS_DATE_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}$/;
// 2020-02-27T18:37:14.000+0000 || 2020-02-27T18:37:14.000+00:00
const XS_DATE_TIME_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-](\d{4}|\d{2}:\d{2})$/;
const XE_LOCAL_DATE_TIME_FORMAT_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;

/**
 * @param {Object} jsDateOrLuxonDateTime - A JS Date or Luxon DateTime object
 * @return {string} A date in the XS_DATE_FORMAT
 */
export const toXsDateString = (jsDateOrLuxonDateTime) => {
  if (!jsDateOrLuxonDateTime) return jsDateOrLuxonDateTime;

  if (jsDateOrLuxonDateTime instanceof Date) {
    return DateTime.fromJSDate(jsDateOrLuxonDateTime).toFormat(XS_DATE_FORMAT);
  }
  if (DateTime.isDateTime(jsDateOrLuxonDateTime)) {
    return jsDateOrLuxonDateTime.toFormat(XS_DATE_FORMAT);
  }
  throw new Error(
    'An invalid input was provided to date formatter toXsDateString'
  );
};

const XS_DATE_TIME_STRING_LENGTH = 28;

/**
 * @param {Object} jsDateOrLuxonDateTime - A JS Date or Luxon DateTime object
 * @return {string} A date in the XS_DATE_TIME_FORMAT
 */
export const toXsDateTimeString = (jsDateOrLuxonDateTime) => {
  if (!jsDateOrLuxonDateTime) return jsDateOrLuxonDateTime;

  // There is a Luxon bug that does not account for possible floating point timezone offset values
  // So some timezone offset strings have extra decimal values appended, e.g. "0002-06-04T11:40:32.000-0550.60000000000002"
  // This bug affects all dates prior to November 18, 1883 (the adoption of railway timezone in the USA) in Firefox.
  // A Luxon bug ticket has been filed
  // https://github.com/moment/luxon/issues/724
  //
  // Until this bug is fixed, we have to truncate the datetime string
  if (jsDateOrLuxonDateTime instanceof Date) {
    return DateTime.fromJSDate(jsDateOrLuxonDateTime)
      .toFormat(XS_DATE_TIME_FORMAT)
      .substring(0, XS_DATE_TIME_STRING_LENGTH);
  }
  if (DateTime.isDateTime(jsDateOrLuxonDateTime)) {
    return jsDateOrLuxonDateTime
      .toFormat(XS_DATE_TIME_FORMAT)
      .substring(0, XS_DATE_TIME_STRING_LENGTH);
  }

  throw new Error(
    'An invalid input was provided to date formatter toXsDateTimeString'
  );
};

/**
 * @param {Object} jsDateOrLuxonDateTime - A JS Date or Luxon DateTime object
 * @return {string} A date in the XE_LOCAL_DATE_TIME_FORMAT
 */
export const toXeLocalDateTimeString = (jsDateOrLuxonDateTime) => {
  if (!jsDateOrLuxonDateTime) return jsDateOrLuxonDateTime;

  if (jsDateOrLuxonDateTime instanceof Date) {
    return DateTime.fromJSDate(jsDateOrLuxonDateTime).toFormat(
      XE_LOCAL_DATE_TIME_FORMAT
    );
  }
  if (DateTime.isDateTime(jsDateOrLuxonDateTime)) {
    return jsDateOrLuxonDateTime.toFormat(XE_LOCAL_DATE_TIME_FORMAT);
  }
  throw new Error(
    'An invalid input was provided to date formatter XE_LOCAL_DATE_TIME_FORMAT'
  );
};

/**
 * @param {string} dateStr - A string in the XS_DATE_FORMAT
 * @return {Date} A JS Date
 */
export const fromXsDateString = (dateStr) => {
  //Date format: "yyyy-MM-dd"
  //xs:date
  const timezoneOffset = new Date().getTimezoneOffset();
  return DateTime.fromFormat(dateStr, XS_DATE_FORMAT)
    .plus({ minutes: timezoneOffset })
    .toJSDate();
};

/**
 * @param {string} str - A string in the XS_DATE_TIME_FORMAT
 * @return {Date} A JS Date representing a universal DateTime
 */
const xsDateTimeStringPattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})([-+])(\d{2})(\d{2})$/;
export const fromXsDateTimeString = (dateTimeStr) => {
  const tokens = xsDateTimeStringPattern.exec(dateTimeStr) || [];
  if (tokens.length < 11) {
    throw new Error(
      `Asked to parse non-conforming string ${dateTimeStr} to format ${XS_DATE_TIME_FORMAT}`
    );
  }
  const [
    ,
    year,
    month,
    day,
    hour,
    minute,
    second,
    millisecond,
    offsetDirection,
    offsetHours,
    offsetMinutes,
  ] = tokens;

  const theDate = DateTime.fromObject({
    year,
    month,
    day,
    hour,
    minute,
    second,
    millisecond,
  }).toJSDate();

  const neededOffsetMins =
    (offsetHours * 60 + offsetMinutes * 1) * (offsetDirection == '-' ? 1 : -1);
  const existingOffsetMins = theDate.getTimezoneOffset();

  if (existingOffsetMins === neededOffsetMins) {
    return theDate;
  }

  const delta = neededOffsetMins - existingOffsetMins;
  theDate.setMinutes(theDate.getMinutes() + delta);
  return theDate;
};

/**
 * @param {string} localDateTimeStr - A string in the XE_LOCAL_DATE_TIME_FORMAT
 * @return {Date} A JS Date representing a local DateTime
 */
export const fromXeLocalDateTimeString = (localDateTimeStr) => {
  //LocalDateTime format: "yyyy-MM-dd'T'HH:mm:ss.SSS"
  return DateTime.fromFormat(
    localDateTimeStr,
    XE_LOCAL_DATE_TIME_FORMAT
  ).toJSDate();
};

// Note on the expected interface of { parser, formatter } tuples:
// A parser takes a string and yields a JS Date
// A formatter takes a JS Date or Luxon DateTime and yields a string
const knownFormatters = {
  [XS_DATE_FORMAT]: {
    parser: fromXsDateString,
    formatter: toXsDateString,
  },
  [XS_DATE_TIME_FORMAT]: {
    parser: fromXsDateTimeString,
    formatter: toXsDateTimeString,
  },
  [XE_LOCAL_DATE_TIME_FORMAT]: {
    parser: fromXeLocalDateTimeString,
    formatter: toXeLocalDateTimeString,
  },
};

//Should be called by our date components
export const toFormatterAndParserObject = (format) => {
  const formatterParserObject = knownFormatters[format];
  if (formatterParserObject) {
    return formatterParserObject;
  }
  throw new Error(
    `No formatter/parser tuple exists to parse format '${format}'`
  );
};

export const toDateFromISOString = (isoString) => {
  if (XS_DATE_TIME_FORMAT_REGEX.test(isoString)) {
    return fromXsDateTimeString(isoString);
  }

  if (XE_LOCAL_DATE_TIME_FORMAT_REGEX.test(isoString)) {
    return fromXeLocalDateTimeString(isoString);
  }

  if (XS_DATE_FORMAT_REGEX.test(isoString)) {
    return fromXsDateString(isoString);
  }

  throw new Error(`Attempt to convert unknown date format '${isoString}'`);
};

/**
 * @param {string} isoString - a date or datetime string in one of the ISO formats we support
 * @return {DateTime} a Luxon DateTime object
 */
export const isoStrAsLuxon = (isoString) => {
  const asDate = toDateFromISOString(isoString);
  return DateTime.fromJSDate(asDate);
};

//This returns the { parser, formatter } based on type.
export const toFormatterAndParserByType = (type) => {
  if (type === 'date') return toFormatterAndParserObject(XS_DATE_FORMAT);
  if (type === 'date-time')
    return toFormatterAndParserObject(XS_DATE_TIME_FORMAT);
  if (type === 'local-date-time')
    return toFormatterAndParserObject(XE_LOCAL_DATE_TIME_FORMAT);

  throw new Error(`No formatter/parser tuple exists for type'${type}'`);
};

// The following functions are used with kendo components and as inputs to ISO formatters
// So they have to return JS Date objects
export const toNowDate = () => DateTime.local().toJSDate();

export const toDateInNUnits = (isoString, config) => {
  const jsDate = toDateFromISOString(isoString);
  return DateTime.fromJSDate(jsDate).plus(config);
};

export const toDateInNDays = (isoString, days) => {
  return toDateInNUnits(isoString, { days });
};

/**
 * @param {import('luxon').Duration | Object | number} timeDifferenceObj - a time interval object as defined in luxon's api ex. ({days: 1})
 * @return {DateTime} a Luxon DateTime object
 */

export const toDateRelativeToNow = (timeDifferenceObj = {}) => {
  return DateTime.local().plus(timeDifferenceObj);
};

// If the string input in a special token value, i.e. 'NOW', return a special value
// Otherwise use the provided parser if the input is defined
export const toParseWithToken = (parser) => (dateStringOrToken) => {
  if (!dateStringOrToken) return undefined;
  //TODO: currently we only support the "now" token, but could potentially support more in the future.
  if (dateStringOrToken === DATE_NOW_TOKEN) return toNowDate();
  if (dateStringOrToken === DATE_NOW_START_OF_DAY) {
    return DateTime.local().startOf('day').toJSDate();
  }

  return parser(dateStringOrToken);
};

/*
  Date String PropTypes
*/

export const XsDateTimeStringPropType = (props, propName, componentName) => {
  const value = props[propName];
  if (!XS_DATE_TIME_FORMAT_REGEX.test(value)) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. Validation failed.`
    );
  }
};

export const DateTimeFormatNamePropType = PropTypes.oneOf([
  'date-time',
  'local-date-time',
  'date',
]);
