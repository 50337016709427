import { useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EMPTY_OBJECT } from '../constants';
import { useEnterprise } from '../contexts/XeEnterpriseContext';
import {
  toAbsoluteUrlFromMenuNode,
  withMenuNodeID,
} from '../contexts/XeMenuNodeContext/components';
import { isObjectLike } from '../fp/pred';

const toAbsoluteUrlForDefaultSearch = (userData, to) => {
  const { DefaultSearchFeature } = userData;

  if (DefaultSearchFeature === undefined) {
    //We were asked to navigate to the DefaultSearchFeature, but we don't have one
    //So this is effectively a noop
    console.warn(`Asked to navigate to DefaultSearchFeature but none defined`);
    return;
  }

  const [
    ,
    ,
    //tilde
    //DefaultSearchFeature
    ipid,
  ] = to.split('/') ?? [];

  return (
    toAbsoluteUrlFromMenuNode(userData, withMenuNodeID(DefaultSearchFeature), {
      ipid,
    }) ?? '/'
  );
};

export function usePathNavigate() {
  const routerNavigate = useNavigate();
  const { userData = EMPTY_OBJECT } = useEnterprise();
  const params = useParams();

  let activeRef = useRef(false);
  useEffect(() => {
    activeRef.current = true;
  });

  const navigate = useCallback(
    (to = '', options = {}) => {
      if (activeRef.current) {
        if (typeof to === 'number') {
          navigator.go(to);
        } else {
          if (isObjectLike(to)) {
            //Lovely, we have a node.... we can make that work
            const path =
              toAbsoluteUrlFromMenuNode(
                userData,
                withMenuNodeID(to?.MenuNodeID),
                params
              ) ?? '/';
            routerNavigate(path, options);
            // } else if (to.startsWith('./')) {
            //   //Local navigation, default React Router style
            //   path = resolvePath(to.slice(1), pathname, basename);
          } else if (to.startsWith('~/')) {
            const { EnterpriseID = '' } = userData;

            if (to.includes('DefaultSearchFeature')) {
              return routerNavigate(
                toAbsoluteUrlForDefaultSearch(userData, to),
                options
              );
            }

            //Relative to the enterprise... a few more tricks here
            routerNavigate(`/${EnterpriseID}${to.slice(1)}`, options);
          } else {
            // do whatever React Router wants to do
            routerNavigate(to, options);
          }
        }
      } else {
        console.warn(
          false,
          `You should call navigate() in a useEffect, not when ` +
            `your component is first rendered.`
        );
      }
    },
    [userData, params, routerNavigate]
  );

  return navigate;
}
