import { useEffect, useRef } from 'react';
import { castFunction } from '../fp/fp';

export const useCleanup = (cb) => {
  const cbRef = useRef(cb);
  if (cbRef.current !== cb) {
    cbRef.current = cb;
  }
  useEffect(() => {
    const fn = castFunction(cbRef.current);
    return () => fn();
  }, []);
};
