import { useRef, useState } from 'react';

// TODO: (SYNUI-5885) I'm a little iffy on the name of this hook, but the uses of this should be limited to a very small number of places.
// If this becomes a problem, revisit alternatives (JDM)
/*
 * Motivation:
 * The `Dashboard` component has an async fetch to get smartbook data. There is a potential (and proven) timing issue
 * where the view loads before that request finishes, resulting in the view showing no filters as they were undefined
 * when `useState` was initially invoked.
 *
 * I don't believe that this hook should be used often as there are likely better ways that a view should respond to data changes,
 * so think of this as a hook for breaking the glass in situations like this.
 */

/**
 * @param {S} value Initial value of the hook. Changes to this reference will result in the hook returning the next changed reference
 * @return {[S, import('react').Dispatch<import('react').SetStateAction<S>>]}
 * @template S
 */
export const useStateFromValue = (value) => {
  const [syncValue, setSyncValue] = useState(value);
  const lastValueRef = useRef(value);

  if (value !== lastValueRef.current) {
    lastValueRef.current = value;
    setSyncValue(value);
  }

  return [syncValue, setSyncValue];
};
