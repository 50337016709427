import XeAuth0Authenticator from '../XeAuth0Authenticator';
import { fromLocalStorage } from '../../local/localStorage';
import { EMPTY_OBJECT } from '../../constants';
import { useEffect } from 'react';
import toXnetAuthClient from '../../auth/xnetAuthClient';

const XePreAuthenticated = (props) => {
  const { onAuthClient } = props;

  const { authorization, loginName } =
    fromLocalStorage('xnetAuthorization') ?? EMPTY_OBJECT;

  useEffect(() => {
    if (authorization !== undefined && onAuthClient !== undefined) {
      /** If things go super fast.... this useEffect() executes before the parent's
       * useEffect. So that means that the parent's navigate() refuses to run as it
       * believes we are trying to call it synchronlously. This schedules
       * a microtask, getting us around that situation....sigh
       **/
      queueMicrotask(() => {
        onAuthClient(toXnetAuthClient(loginName, authorization));
      });
    }
  }, [loginName, authorization, onAuthClient]);

  return null;
};

export const XeProductionAuthenticator = (props) => {
  const { authProvider = 'auth0' } = props;

  if (authProvider === 'xnet') {
    return <XePreAuthenticated {...props} />;
  } else if (authProvider === 'auth0') {
    return <XeAuth0Authenticator {...props} />;
  }

  return null;
};

export default XeProductionAuthenticator;
