import { useRef } from 'react';

import { isShallowEqual } from '../fp/object';

export const UNINITIALIZED = Symbol('UNINITIALIZED');
/**
 *
 * @param {() => T} factory
 * @param {any[]} deps
 * @returns {T}
 *
 * @template T
 */
export const useFactory = (factory, deps) => {
  const instanceRef = useRef(UNINITIALIZED);
  const memoRef = useRef(deps);

  if (
    instanceRef.current === UNINITIALIZED ||
    !isShallowEqual(deps, memoRef.current)
  ) {
    instanceRef.current = factory();
    memoRef.current = deps;
  }

  return instanceRef.current;
};
