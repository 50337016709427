import { or, not, isObjectLike } from './pred';
import { pipe } from './fp';

/*Always needs a seed value for now*/
const reduce = (reducer, seed) => (data) => {
  if (Array.isArray(data)) {
    return data.reduce(reducer, seed);
  }

  return Object.entries(data).reduce((acc, [k, v]) => {
    return reducer(acc, v, k, data);
  }, seed);
};

export const keyNamed = (name) => (v, k) => {
  return k === name;
};

export const keep = (...preds) => (data) => {
  const pred = or(...preds);

  if (data === undefined) return undefined;

  if (Array.isArray(data)) {
    return data.filter(pred);
  }

  return reduce((acc, v, k, obj) => {
    if (pred(v, k, obj)) {
      acc[k] = v;
    }

    return acc;
  }, {})(data);
};

export const discard = (...preds) => {
  const notPred = not(or(...preds));
  return keep(notPred);
};

const innerTransform = (prop) => {
  return (fn) => {
    return (data) => {
      if (data === undefined) return data;

      const { [prop]: selected, ...rest } = data;

      const transformed = Array.isArray(selected)
        ? selected.map(fn)
        : fn(selected);

      return {
        ...rest,
        [prop]: transformed,
      };
    };
  };
};

export const transform = (...props) => {
  return (...fns) => {
    const fn = fns.length > 1 ? pipe(...fns) : fns[0];

    return props
      .reverse()
      .reduce(
        (accTransformFn, prop) => innerTransform(prop)(accTransformFn),
        fn
      );
  };
};
