import { isObjectLike } from '../fp/pred';
import { useMenuNode } from '../contexts/XeMenuNodeContext';
import { useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';
import { EMPTY_OBJECT } from '../constants';
import { toCacheKeyFromRequest } from '../service/serviceCache';

const toResponseData = (response, selectFn) => {
  const preliminary =
    response !== undefined && response.results !== undefined
      ? response.results
      : response;

  if (!selectFn) {
    return preliminary;
  } else {
    return selectFn(preliminary);
  }
};

// TODO: A lot of this code is reused from useXeQuery (JDM)
export const useXeQueries = (arrayOfItems, toRequestOptions) => {
  const menuNode = useMenuNode();

  const {
    requestConfigFn: defaultRequestConfigFn,
    requestInvoker: defaultRequestInvoker,
  } = menuNode;
  const { requestConfigFn: overrideRequestConfigFn, overrideRequestInvoker } =
    toRequestOptions();

  const requestConfigFn = overrideRequestConfigFn ?? defaultRequestConfigFn;
  const requestInvoker = overrideRequestInvoker ?? defaultRequestInvoker;

  return useQueries(
    arrayOfItems.map((item) => {
      const { queryFn: toPreliminaryRequestFn, ...options } =
        toRequestOptions(item);

      const reducedConfig = requestConfigFn()(toPreliminaryRequestFn());
      const queryKey = toCacheKeyFromRequest(reducedConfig);
      return {
        queryKey,
        queryFn: () => {
          const controller = new AbortController();
          const signal = controller.signal;

          //We _only_ support fullRequest
          const promise = requestInvoker({ fullRequest: true })({
            signal,
            ...reducedConfig,
          }).catch((errorResponse) => {
            const { name } = errorResponse;

            if (name !== 'AbortError') {
              //Do not display abort errors... these are okay and good, means we cancelled useless fetch requests
              throw errorResponse;
            }

            return undefined;
          });

          promise.cancel = () => {
            //console.log(reducedConfig.service, reducedConfig.action, reducedConfig.params.ipid);
            controller.abort();
          };

          return promise;
        },
        ...options,
      };
    })
  );
};

export const useXeQuery = (preliminaryRequest, options = EMPTY_OBJECT) => {
  const menuNode = useMenuNode();

  const {
    requestConfigFn: defaultRequestConfigFn,
    requestInvoker: defaultRequestInvoker,
  } = menuNode;
  const { requestConfigFn: overrideRequestConfigFn, overrideRequestInvoker } =
    options;

  const requestConfigFn = overrideRequestConfigFn ?? defaultRequestConfigFn;
  const requestInvoker = overrideRequestInvoker ?? defaultRequestInvoker;

  const reducedConfig = requestConfigFn()(preliminaryRequest);
  const queryKey = toCacheKeyFromRequest(reducedConfig);

  const { select, ...restOptions } = options;

  const queryResult = useQuery(
    queryKey,
    () => {
      const controller = new AbortController();
      const signal = controller.signal;

      //We _only_ support fullRequest
      const promise = requestInvoker({ fullRequest: true })({
        signal,
        ...reducedConfig,
      }).catch((errorResponse) => {
        const { name } = errorResponse;

        if (name !== 'AbortError') {
          //Do not display abort errors... these are okay and good, means we cancelled useless fetch requests
          throw errorResponse;
        }

        return undefined;
      });

      promise.cancel = () => {
        //console.log(reducedConfig.service, reducedConfig.action, reducedConfig.params.ipid);
        controller.abort();
      };

      return promise;
    },
    restOptions
  );

  const memoized = useMemo(() => {
    const { data, ...rest } = queryResult;

    //For right now at least, we memoize this so that we can mix in the xnet specific properties into the response
    const {
      AllResults = undefined,
      NumberOfResults = undefined,
      NumberRestricted = undefined,
      RestrictProcessed = undefined,
      Restricted = undefined,
      request = EMPTY_OBJECT,
    } = isObjectLike(data) ? data : EMPTY_OBJECT;

    return {
      ...rest,
      AllResults,
      NumberOfResults,
      NumberRestricted,
      RestrictProcessed,
      Restricted,
      request, //might need to figure out how to mix in the request before the return but that gets tricky with caching
      data: toResponseData(data, select),
    };
  }, [queryResult, select]);

  return memoized;
};
