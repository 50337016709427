import '../styles.css';

export default (props) => {
  // const {
  //   error : {
  //     message = ''
  //   } = {}
  // } = props;

  return (
    <div className="error">
      <div className="header">
        <div className="logo">
          <img src="https://www.thrasys.com/img/thrasys-logo.svg" />
        </div>
        <h3 className="header__title">Thrasys</h3>
        <h3 className="header__title--sub">You have been logged out of this session.</h3>
      </div>
      <div className="main">
        <p>
          You have been logged out of this session.
        </p>
      </div>
      <div className="footer"></div>
    </div>
  );
};
