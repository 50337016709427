const initialState = {};

import { ADD_FEATURE, REMOVE_FEATURE } from '../../hooks/scopedReducer';
import {
  APP_READY,
  REQUEST_CONFIG_FN,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  REQUEST_INVOKER,
  AUTH_CLIENT,
} from './actions';

const toActionReducer =
  (destinationType) =>
  (state = undefined, action) => {
    const { type, value } = action;

    if (type !== destinationType) return state;

    return value;
  };

const updateReducers = (state, action) => {
  const { type, scope = '', value: { reducer } = {} } = action;

  const { dynamicReducers = [], removedFeatures = {} } = state;

  if (type === ADD_FEATURE) {
    return {
      ...state,
      dynamicReducers: [...dynamicReducers, reducer],
    };
  } else if (type === REMOVE_FEATURE) {
    return {
      ...state,
      removedFeatures: { ...removedFeatures, [scope]: true },
      dynamicReducers: dynamicReducers.filter((r) => r !== reducer),
    };
  }

  return state;
};

export const dynamicReducer = (state0 = initialState, action) => {
  const { type } = action;

  if (type === REMOVE_FEATURE) {
    //Ensure reducers about to be removed see their curtain call
    const { dynamicReducers = [] } = state0;

    return updateReducers(
      dynamicReducers.reduce((acc, reducer) => reducer(acc, action), state0),
      action
    );
  }

  const state1 = type === ADD_FEATURE ? updateReducers(state0, action) : state0;

  const { dynamicReducers = [] } = state1;

  //This is where the big magic happens.... all of the reducers from all of the features run here
  return dynamicReducers.reduce((acc, reducer) => reducer(acc, action), state1);
};

const toNotificationsActionReducers =
  (addAction, removeAction) =>
  (notifications = [], action) => {
    const { type, value } = action;
    if (addAction === type) {
      return [...notifications, value];
    }

    if (removeAction === type) {
      const { id } = value;
      return notifications.filter((notification) => notification.id !== id);
    }

    return notifications;
  };

export default {
  dynamicReducer,
  appReady: toActionReducer(APP_READY),
  requestInvoker: toActionReducer(REQUEST_INVOKER),
  requestConfigFn: toActionReducer(REQUEST_CONFIG_FN),
  notifications: toNotificationsActionReducers(
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION
  ),
  authClient: toActionReducer(AUTH_CLIENT),
};
