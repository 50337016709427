import { filter, map, switchMap, take } from 'rxjs/operators';

export const withLatestValuesFrom =
  (source$, ...interestingProps) =>
  (obs$) =>
    obs$.pipe(
      switchMap((obsValue) => {
        return source$.pipe(
          filter((x) => x),
          map((lastSet) => interestingProps.map((p) => lastSet[p])),
          //Making an assumption here that we want _every_ interesting prop to be defined
          filter((props) => props.every((prop) => prop !== undefined)),
          map((props) => [obsValue, ...props]),
          take(1)
        );
      })
    );
