import { downloadFile } from '../download/downloadFile';
import { isNil } from '../fp/pred';
import { useEffect, useState } from 'react';
import { getFile } from 'services/file-storages/xe-file-storages-svc';
import { useXeQuery } from '../data/useXeQuery';

const unsetFileData = {
  fileId: undefined,
  fileName: '',
};

export const useRequestDownloadFile = () => {
  const [lastFileData, setFileData] = useState(unsetFileData);

  const { fileId: lastFileId, fileName } = lastFileData;

  const { refetch } = useXeQuery(
    getFile({ fileId: lastFileId }, (x) => x),
    {
      enabled: false,
      onSuccess: (blob) => {
        downloadFile(blob, fileName);
        return setFileData(unsetFileData);
      },
    }
  );

  useEffect(() => {
    const { fileId } = lastFileData;
    if (isNil(fileId)) return;
    refetch();
  }, [refetch, lastFileData]);

  return (fileId, fileName) => {
    setFileData({
      fileId,
      fileName,
    });
  };
};
