export const DURATION_FORMATS = {
  TIME: `h'h' m'm' s's'`,
};

const PERIOD_UNIT_MAP = {
  Y: `y'y'`,
  M: `M'm'`,
  D: `d'd'`,
};

const TIME_UNIT_MAP = {
  H: `h'h'`,
  M: `m'm'`,
  S: `s's'`,
};

/**
 *
 * @param {String} isoSegment
 * @param {*} unitMap
 */
const toUnitChars = (isoSegment, unitMap) => {
  if (!isoSegment) return [];
  const matches = isoSegment.match(/\d+[A-Z]{1}/g);
  if (!matches) return [];
  return matches.map((segmentUnit = '') => {
    const unitChar = segmentUnit.match(/[A-Z]/);
    return unitMap[unitChar];
  });
};

// TODO: Determine if an options object as the second argument to toFormattedDuration is a good approach (JDM)
/**
 * @typedef ToFormattedDurationOptions
 * @property {import('luxon').DurationToFormatOptions} formatOptions
 * @property {*} format
 * @property {'years'|'months'|'days'|'hours'|'minutes'|'seconds'} accuracy
 */

/**
 * Formats a luxon duration for display
 * @param {import('luxon').Duration} duration
 * @param {*} format One of DURATION_FORMATS from xnetjs/g11n/displayDuration
 */
export const toFormattedDuration = (duration, format) => {
  if (format) {
    return duration.toFormat(DURATION_FORMATS[format]);
  }

  /*
   * The following approach was me trying my best to infer a format based on any
   * luxon duration object passed in. If this isn't quit the direction we'd like to
   * go in, I would love to follow up on other approaches.
   *
   * This uses the ISO duration standard to determine how to format the duration object. (JDM)
   */
  const shiftedDuration = duration.shiftTo(
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds'
  );
  const [period, time] = shiftedDuration.toISO().split('T');
  const periodUnits = toUnitChars(period, PERIOD_UNIT_MAP);
  const timeUnits = toUnitChars(time, TIME_UNIT_MAP);

  // This only uses the first two time units to display in the order from years to seconds.
  // TODO: This could benefit from an option specifying how accurate this string should be (JDM)
  // eg. down the hour, minute, second etc
  const determinedFormat = [...periodUnits, ...timeUnits].slice(0, 2).join(' ');
  return duration.toFormat(determinedFormat);
};
