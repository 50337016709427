import createAuth0Client from '@auth0/auth0-spa-js';
import { useEffect$ } from '../../hooks/useEffect$';
import { neverCache } from '../../service/serviceCache';
import { from } from 'rxjs';
import { usePathNavigate } from '../../hooks/usePathNavigate';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants';

export const XeAuth0Authenticator = (props) => {
  const {
    universe: {
      environment: { authProvider, toWindow } = EMPTY_OBJECT,
    } = EMPTY_OBJECT,
    onAuthClient,
    requestInvoker,
    requestConfigFn,
    isAuthCallback,
  } = props;

  const navigate = usePathNavigate();

  const {
    location: { origin = '', pathname = '', hash = '' },
  } = toWindow();

  useEffect$(() => {
    const requestFn = (config) => {
      const finalConfig = requestConfigFn(neverCache)()(config);
      return requestInvoker(/*No additional args*/)(finalConfig);
    };

    const url = `/${authProvider}-config.json`;
    const requestBody = {
      method: 'GET',
      responseType: 'json',
      url,
    };

    return from(
      requestFn(requestBody)
        .then((config) => {
          return createAuth0Client(config);
        })
        .then((client) => {
          if (isAuthCallback) {
            return client.handleRedirectCallback().then(() => {
              onAuthClient(client);
            });
          } else {
            return client.isAuthenticated().then((authenticated) => {
              if (authenticated) {
                onAuthClient(client);
              } else {
                const [, searchSegment] = hash.split('?') ?? EMPTY_ARRAY;
                const search = searchSegment ? `?${searchSegment}` : '';
                const options = {
                  redirect_uri: `${origin}${pathname}#/callback${search}`,
                };

                return client.loginWithRedirect(options);
              }
            });
          }
        })
        .catch((e) => {
          console.error(e);
          navigate(`/denied`);
        })
    );
  }, [authProvider, isAuthCallback, requestInvoker, requestConfigFn]);

  return null;
};

export default XeAuth0Authenticator;
