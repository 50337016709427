import { useEffect } from 'react';
import { EMPTY_OBJECT } from '../../../constants';
import '../styles.css';

export default (props) => {
  // const {
  //   error : {
  //     message = ''
  //   } = {}
  // } = props;
  const {
    universe : {
      environment: { 
        toWindow 
      } = EMPTY_OBJECT 
    } = EMPTY_OBJECT,
    authClient
  } = props;

  useEffect(()=>{
    if (authClient) {
      //we got here because we do have a valid auth client, but we are not allowed to access the app
      //per mark, wait a few seconds, then kick (log) them out
      setTimeout(()=>{ 
        const {
          location: { origin = '', pathname = '' },
        } = toWindow();
        
        authClient.logout({
          returnTo: `${origin}${pathname}`,
        });
      }, 4000);
    }
  }, [authClient, toWindow]);

  return (
    <div className="error">
      <div className="header">
        <div className="logo">
          <img src="https://www.thrasys.com/img/thrasys-logo.svg" />
        </div>
        <h3 className="header__title">Thrasys</h3>
        <h3 className="header__title--sub">Oops! Something went wrong.</h3>
      </div>
      <div className="main">
        <p>
          You do not have access to this application at {document.location.host}
          .
        </p>
        <p>
          If you believe you have received this page in error, please contact
          your support organization for assistance.
        </p>
      </div>
      <div className="footer"></div>
    </div>
  );
};
