
export const DefaultView = ({ componentPath = '' }) => {
  return (
    <div className="flex-1 vertical-flex-container align-items-center">
      <div className="flex-1 vertical-flex-container justify-content-center">
        <h1>Under Construction</h1>
      </div>
      <div className="align-self-end padding-all-small">{componentPath}</div>
    </div>
  );
};

export default DefaultView;
